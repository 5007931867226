export default create_transaction

const TRANSACTION_PERMISSIONS = {
  READ_ONLY: 'readonly',
  READWRITE: 'readwrite',
}

create_transaction.TRANSACTION_PERMISSIONS = TRANSACTION_PERMISSIONS

function create_transaction({ client, databases, permissions = TRANSACTION_PERMISSIONS.READ_ONLY }) {
  const transaction = client.transaction( databases, permissions )

  const transaction_completed_promise = new Promise(( resolve, reject ) => {
    transaction.oncomplete = resolve

    transaction.onerror = () => reject( new Error( transaction.error ?? 'unspecified error' ) )
    transaction.onabort = () => reject( new Error( transaction.error ?? 'transaction aborted' ) )
  })

  return {
    transaction,
    transaction_completed_promise,
  }
}