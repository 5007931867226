import { ReactComponent as SparkSVG } from '../../svgs/spark-icon.svg'

import './style.css'

const SparkpocketjoyLogo = () => {
  return (
    <div className='LogoBlock'>
      <span className='Logo'>
        <SparkSVG />
      </span>
      <span className='Wordmark'>
        {
          'sparkpocketjoy'
            .split('')
            .map(( char, char_index ) => {
              const key = `index-${ char_index + 1 }`
              return (
                <span key={ key } className={ `WordmarkChar ${ key }` }>
                  { char }
                </span>
              )
            })
        }
      </span>
    </div>
  )
}

export default SparkpocketjoyLogo
