import { useState } from 'react'

import { ReactComponent as TriangleUpSVG } from '../../svgs/triangle-point-up-icon.svg'
import { ReactComponent as TriangleDownSVG } from '../../svgs/triangle-point-down-icon.svg'

import './style.css'

const MainDisplayModule = ({ id, label, inline, expanded, initialIsInline, onDisplayHistoryUpdate }) => {
  const [ isInline, setIsInline ] = useState(
    initialIsInline !== undefined
      ? initialIsInline
      : true
  )

  return <>
    <div id={ id } className={ `MainDisplayModule${ isInline ? '' : ' expanded' }` }>
      <div className="Header">
        <div className="Label">{ label }</div>
        {
          isInline && <div className="Inline">{ inline }</div>
        }
        <div className="ToggleView" onClick={() => {
          const toggledIsInline = !isInline
          setIsInline( toggledIsInline )
          onDisplayHistoryUpdate({ expanded: !toggledIsInline })
        }}>
          {
            isInline
              ? <TriangleDownSVG />
              : <TriangleUpSVG />
          }
        </div>
      </div>
      {
        !isInline && <div className="Expanded">{ expanded }</div>
      }
    </div>
  </>
}

export default MainDisplayModule
