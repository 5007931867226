const VALID_KEYS = {
  indexed_db_module: 'indexed_db_module',
  indexed_db_initialized: 'indexed_db_initialized',
  indexed_db_action: 'indexed_db_action',
  indexed_db_supported: 'indexed_db_supported',
  indexed_db_client: 'indexed_db_client',

  display_history_module: 'display_history_module',
  display_history_initialized: 'display_history_initialized',
  display_history_module_actions: 'display_history_module_actions',
  display_history_module_action: 'display_history_module_action',
  display_history_module_state: 'display_history_module_state',

  pocket_auth_module: 'pocket_auth_module',
  pocket_auth_module_state: 'pocket_auth_module_state',
  pocket_auth_module_actions: 'pocket_auth_module_actions',
  pocket_auth_initialized: 'pocket_auth_initialized',
  pocket_auth_action: 'pocket_auth_action',
  pocket_auth_user: 'pocket_auth_user',
  username: 'username',
  access_token: 'access_token',

  sync_status_module: 'sync_status_module',
  sync_status_module_state: 'sync_status_module_state',
  sync_status_module_actions: 'sync_status_module_actions',
  sync_status_action: 'sync_status_action',
  sync_status_initialized: 'sync_status_initialized',
  sync_status_wrapper: 'sync_status_wrapper',
  current_sync_status: 'current_sync_status',
  next_sync_status: 'next_sync_status',
  sync_id: 'sync_id',
  daily_pocket_sync: 'daily_pocket_sync',

  data_indexes_module: 'data_indexes_module',
  data_indexes_module_state: 'data_indexes_module_state',
  data_indexes_initialized: 'data_indexes_initialized',
  data_indexes_action: 'data_indexes_action',
  data_indexes_sync_id: 'data_indexes_sync_id',
  data_indexes: 'data_indexes',

  content_data_module: 'content_data_module',
  content_data_module_state: 'content_data_module_state',
  content_data_initialized: 'content_data_initialized',
  content_data_action: 'content_data_action',
  content_data_sync_id: 'content_data_sync_id',
  content_data: 'content_data',
}

export default pluck

function pluck( args = {} ) {
  if (!args.hasOwnProperty( 'app_context' )) throw new Error( 'app context not specified' )
  if (!args.hasOwnProperty( 'keys_to_pluck' )) throw new Error( 'keys to pluck from app context not specified' )

  const {
    app_context,
    keys_to_pluck,
  } = args ?? {}

  if (Object.prototype.toString.call( keys_to_pluck ) !== '[object Array]') throw new Error( 'expected keys to pluck to be an array, but it isn\'t' )

  const keys_to_pluck_map = {}

  keys_to_pluck.forEach( key_to_pluck => {
    if (!VALID_KEYS[ key_to_pluck ]) throw new Error( `"${ key_to_pluck }" is not a valid key to pluck` )

    keys_to_pluck_map[ key_to_pluck ] = true
  })

  const {
    indexed_db: indexed_db_module,
    pocket_auth: pocket_auth_module,
    sync_status: sync_status_module,
    data_indexes: data_indexes_module,
    content_data: content_data_module,
    display_history: display_history_module,
  } = app_context ?? {}

  const {
    state: indexed_db_module_state,
  } = indexed_db_module ?? {}

  const {
    action: indexed_db_action,
    initialized: indexed_db_initialized,
    connection: indexed_db_client,
    device_support: indexed_db_supported,
  } = indexed_db_module_state ?? {}

  const {
    state: display_history_module_state,
    actions: display_history_module_actions,
  } = display_history_module ?? {}

  const {
    initialized: display_history_initialized,
    action: display_history_module_action,
  } = display_history_module_state ?? {}

  const {
    state: pocket_auth_module_state,
    actions: pocket_auth_module_actions,
  } = pocket_auth_module ?? {}

  const {
    initialized: pocket_auth_initialized,
    action: pocket_auth_action,
    user: pocket_auth_user,
  } = pocket_auth_module_state ?? {}
  const { username, access_token } = pocket_auth_user ?? {}

  const {
    state: sync_status_module_state,
    actions: sync_status_module_actions,
  } = sync_status_module ?? {}

  const {
    action: sync_status_action,
    initialized: sync_status_initialized,
    sync_status: sync_status_wrapper,
  } = sync_status_module_state ?? {}

  const {
    current: current_sync_status,
    next: next_sync_status,
    daily_pocket_sync,
  } = sync_status_wrapper ?? {}

  const { sync_id } = current_sync_status ?? {}

  const { state: data_indexes_module_state } = data_indexes_module ?? {}
  const {
    initialized: data_indexes_initialized,
    sync_id: data_indexes_sync_id,
    action: data_indexes_action,
    data_indexes,
  } = data_indexes_module_state ?? {}

  const { state: content_data_module_state } = content_data_module ?? {}
  const {
    initialized: content_data_initialized,
    action: content_data_action,
    sync_id: content_data_sync_id,
    content_data,
  } = content_data_module_state ?? {}

  const plucked = {}

  if ( keys_to_pluck_map.indexed_db_module ) plucked.indexed_db_module = indexed_db_module
  if ( keys_to_pluck_map.indexed_db_initialized ) plucked.indexed_db_initialized = indexed_db_initialized
  if ( keys_to_pluck_map.indexed_db_action ) plucked.indexed_db_action = indexed_db_action
  if ( keys_to_pluck_map.indexed_db_supported ) plucked.indexed_db_supported = indexed_db_supported
  if ( keys_to_pluck_map.indexed_db_client ) plucked.indexed_db_client = indexed_db_client

  if (keys_to_pluck_map.display_history_module) plucked.display_history_module = display_history_module
  if (keys_to_pluck_map.display_history_initialized) plucked.display_history_initialized = display_history_initialized
  if (keys_to_pluck_map.display_history_module_state) plucked.display_history_module_state = display_history_module_state
  if (keys_to_pluck_map.display_history_module_actions) plucked.display_history_module_actions = display_history_module_actions
  if (keys_to_pluck_map.display_history_module_action) plucked.display_history_module_action = display_history_module_action

  if ( keys_to_pluck_map.pocket_auth_module ) plucked.pocket_auth_module = pocket_auth_module
  if ( keys_to_pluck_map.pocket_auth_module_state ) plucked.pocket_auth_module_state = pocket_auth_module_state
  if ( keys_to_pluck_map.pocket_auth_module_actions ) plucked.pocket_auth_module_actions = pocket_auth_module_actions
  if ( keys_to_pluck_map.pocket_auth_initialized ) plucked.pocket_auth_initialized = pocket_auth_initialized
  if ( keys_to_pluck_map.pocket_auth_action ) plucked.pocket_auth_action = pocket_auth_action
  if ( keys_to_pluck_map.pocket_auth_user ) plucked.pocket_auth_user = pocket_auth_user
  if ( keys_to_pluck_map.username ) plucked.username = username
  if ( keys_to_pluck_map.access_token ) plucked.access_token = access_token

  if ( keys_to_pluck_map.sync_status_module ) plucked.sync_status_module = sync_status_module
  if ( keys_to_pluck_map.sync_status_module_state ) plucked.sync_status_module_state = sync_status_module_state
  if ( keys_to_pluck_map.sync_status_module_actions ) plucked.sync_status_module_actions = sync_status_module_actions
  if ( keys_to_pluck_map.sync_status_action ) plucked.sync_status_action = sync_status_action
  if ( keys_to_pluck_map.sync_status_initialized ) plucked.sync_status_initialized = sync_status_initialized
  if ( keys_to_pluck_map.sync_status_wrapper ) plucked.sync_status_wrapper = sync_status_wrapper
  if ( keys_to_pluck_map.current_sync_status ) plucked.current_sync_status = current_sync_status
  if ( keys_to_pluck_map.next_sync_status ) plucked.next_sync_status = next_sync_status
  if ( keys_to_pluck_map.sync_id ) plucked.sync_id = sync_id
  if ( keys_to_pluck_map.daily_pocket_sync ) plucked.daily_pocket_sync = daily_pocket_sync

  if (keys_to_pluck_map.data_indexes_module) plucked.data_indexes_module = data_indexes_module
  if (keys_to_pluck_map.data_indexes_module_state) plucked.data_indexes_module_state = data_indexes_module_state
  if (keys_to_pluck_map.data_indexes_initialized) plucked.data_indexes_initialized = data_indexes_initialized
  if (keys_to_pluck_map.data_indexes_sync_id) plucked.data_indexes_sync_id = data_indexes_sync_id
  if (keys_to_pluck_map.data_indexes_action) plucked.data_indexes_action = data_indexes_action
  if (keys_to_pluck_map.data_indexes) plucked.data_indexes = data_indexes

  if (keys_to_pluck_map.content_data_module) plucked.content_data_module = content_data_module
  if (keys_to_pluck_map.content_data_module_state) plucked.content_data_module_state = content_data_module_state
  if (keys_to_pluck_map.content_data_initialized) plucked.content_data_initialized = content_data_initialized
  if (keys_to_pluck_map.content_data_action) plucked.content_data_action = content_data_action
  if (keys_to_pluck_map.content_data_sync_id) plucked.content_data_sync_id = content_data_sync_id
  if (keys_to_pluck_map.content_data) plucked.content_data = content_data

  return plucked
}
