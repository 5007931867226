import pluck from './pluck'
import create_module from './create-module'
import set_module_actions from './set-module-actions'
import update_module_state from './update-module-state'

const api = {
  pluck,
  create_module,
  set_module_actions,
  update_module_state,
}

export default api
