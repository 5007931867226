import { useCallback, useEffect, useState } from 'react'
import useIndexedDB from '../useIndexedDB'

import appContext from '../../context'
import utils from '../../utils'

export const AUTH_STATES = {
  UNIDENTIFIED: 'AUTH_STATE_UNIDENTIFIED',
  REQUEST_TOKEN: 'AUTH_STATE_REQUEST_TOKEN',
  ACCESS_REQUESTED: 'AUTH_STATE_ACCESS_REQUESTED',
  AUTHORIZED: 'AUTH_STATE_AUTHORIZED',
}

export const ACTIONS = {
  INITIALIZE: 'ACTION_INITIALIZE',
  GET_REQUEST_TOKEN: 'ACTION_GET_REQUEST_TOKEN',
  SEND_USER_TO_POCKET_OAUTH: 'ACTION_SEND_USER_TO_POCKET_OAUTH',
  GET_ACCESS_TOKEN: 'ACTION_GET_ACCESS_TOKEN',
  LOGOUT: 'ACTION_LOGOUT',
}

const { REACT_APP_SPARKPOCKETJOY_API_URL: API_URL } = process.env
if (!API_URL) throw new Error( 'api url not specified' )

const APP_CONTEXT_MODULE_ID = 'pocket_auth'
const GET_REQUEST_TOKEN_URL = `${ API_URL }/integrations/pocket/get-request-token`
const GET_ACCESS_TOKEN_URL = `${ API_URL }/integrations/pocket/get-access-token`

const { useAppContext } = appContext

export default usePocketAuth

export function usePocketAuth() {
  useIndexedDB()

  const [ app_context, set_app_context ] = useAppContext()
  const {
    indexed_db_client,

    pocket_auth_module,
    pocket_auth_module_state,
    pocket_auth_module_actions,
    pocket_auth_initialized,
    pocket_auth_action,
    pocket_auth_user,
  } =
    utils.app_context.pluck({
      app_context,
      keys_to_pluck: [
        'indexed_db_client',

        'pocket_auth_module',
        'pocket_auth_module_state',
        'pocket_auth_module_actions',
        'pocket_auth_initialized',
        'pocket_auth_action',
        'pocket_auth_user',
      ]
    })

  const { request_token } = pocket_auth_user ?? {}
  const {
    last_handled_auth_state,
  } = pocket_auth_module_state ?? {}

  const auth_state = get_pocket_auth_state( pocket_auth_user )

  // bind functions to state with useCallback
  // ===

  const [ is_behavior_instance, set_is_behavior_instance ] = useState( false )
  const callback_create_pocket_auth_module = useCallback( () => {
    set_is_behavior_instance( true )

    return utils.app_context.create_module({
      module_id: APP_CONTEXT_MODULE_ID,
      set_app_context,
      app_context,
    })
  }, [
    app_context,
    set_app_context,
    set_is_behavior_instance,
  ])

  const callback_update_module_state = useCallback( state_updates => {
    return utils.app_context.update_module_state({
      module_id: APP_CONTEXT_MODULE_ID,
      set_app_context,
      app_context,
      state_updates,
    })
  }, [
    set_app_context,
    app_context,
  ])

  const callback_set_module_actions = useCallback( actions => {
    return utils.app_context.set_module_actions({
      module_id: APP_CONTEXT_MODULE_ID,
      set_app_context,
      app_context,
      actions,
    })
  }, [
    set_app_context,
    app_context,
  ])

  const async_callback_action_initialize = useCallback( async () => {
    return await async_action_initialize({
      pocket_auth_initialized,
      pocket_auth_action,
      update_module_state: callback_update_module_state,
      indexed_db_client,
    })
  }, [
    pocket_auth_initialized,
    pocket_auth_action,
    callback_update_module_state,
    indexed_db_client,
  ])

  const async_callback_action_get_request_token = useCallback( async () => {
    return await async_action_get_request_token({
      indexed_db_client,
      pocket_auth_action,
      update_module_state: callback_update_module_state,
    })
  }, [
    indexed_db_client,
    pocket_auth_action,
    callback_update_module_state,
  ])

  const async_callback_action_log_out = useCallback( async () => {
    return await async_action_log_out({
      pocket_auth_action,
      pocket_auth_user,
      auth_state,
      update_module_state: callback_update_module_state,
      indexed_db_client,
    })
  }, [
    pocket_auth_action,
    pocket_auth_user,
    auth_state,
    callback_update_module_state,
    indexed_db_client,
  ])

  const async_callback_action_send_user_to_pocket_oauth_start_url = useCallback( async () => {
    return await async_action_send_user_to_pocket_oauth_start_url({
      request_token,
      indexed_db_client,
      pocket_auth_action,
      update_module_state: callback_update_module_state,
    })
  }, [
    request_token,
    indexed_db_client,
    pocket_auth_action,
    callback_update_module_state,
  ])

  const async_callback_action_get_access_token = useCallback( async () => {
    return await async_action_get_access_token({
      request_token,
      indexed_db_client,
      pocket_auth_action,
      update_module_state: callback_update_module_state,
    })
  }, [
    request_token,
    indexed_db_client,
    pocket_auth_action,
    callback_update_module_state,
  ])

  const async_callback_handle_auth_state = useCallback(async () => {
    return await async_handle_auth_state({
      auth_state,
      pocket_auth_action,
      pocket_auth_initialized,
      last_handled_auth_state,
      update_module_state: callback_update_module_state,
      async_action_get_access_token: async_callback_action_get_access_token,
      async_action_send_user_to_pocket_oauth_start_url: async_callback_action_send_user_to_pocket_oauth_start_url,
    })
  }, [
    auth_state,
    pocket_auth_action,
    pocket_auth_initialized,
    last_handled_auth_state,
    callback_update_module_state,
    async_callback_action_get_access_token,
    async_callback_action_send_user_to_pocket_oauth_start_url,
  ])

  // setup effects
  // ===

  // 1. create pocket auth module if it doesn't exist
  useEffect(() => {
    if (pocket_auth_module) return

    callback_create_pocket_auth_module()
  }, [
    pocket_auth_module,
    callback_create_pocket_auth_module,
  ])

  // 2. initialize pocket auth module
  useEffect(() => {
    if (!is_behavior_instance) return
    if (!pocket_auth_module || !indexed_db_client) return
    if (pocket_auth_initialized || pocket_auth_action) return

    async_callback_action_initialize()
  }, [
    is_behavior_instance,
    pocket_auth_module,
    indexed_db_client,
    pocket_auth_action,
    pocket_auth_initialized,
    async_callback_action_initialize,
  ])

  // 3. set available actions
  useEffect(() => {
    if (!is_behavior_instance) return
    if (!pocket_auth_initialized) return

    const {
      async_log_in,
      async_log_out,
    } = pocket_auth_module_actions ?? {}

    switch (auth_state) {
      case AUTH_STATES.UNIDENTIFIED:
        if (async_log_in && !async_log_out) return;

        callback_set_module_actions({
          async_log_in: async_callback_action_get_request_token,
        })
        break

      case AUTH_STATES.AUTHORIZED:
        if (!async_log_in && async_log_out) return;

        callback_set_module_actions({
          async_log_out: async_callback_action_log_out,
        })
        break

      case AUTH_STATES.REQUEST_TOKEN:
      case AUTH_STATES.ACCESS_REQUESTED:
      default:
        if (!async_log_in && !async_log_out) return;
        callback_set_module_actions({})
        break
    }
  }, [
    is_behavior_instance,
    pocket_auth_initialized,
    pocket_auth_module_actions,
    callback_set_module_actions,
    auth_state,
    async_callback_action_get_request_token,
    async_callback_action_log_out,
  ])

  // 4. handle auth states
  useEffect(() => {
    if (!is_behavior_instance) return
    if (!pocket_auth_initialized || pocket_auth_action) return
    if (auth_state === last_handled_auth_state) return

    async_callback_handle_auth_state()
  }, [
    is_behavior_instance,
    pocket_auth_initialized,
    pocket_auth_action,
    auth_state,
    last_handled_auth_state,
    async_callback_handle_auth_state,
  ])

  return app_context?.[ APP_CONTEXT_MODULE_ID ]
}

export function get_pocket_auth_state( pocket_auth_user = {} ) {
  const {
    username: pocket_auth_username,
    access_token: pocket_auth_access_token,
    request_token: pocket_auth_request_token,
    access_requested: pocket_auth_access_requested,
  } = pocket_auth_user ?? {}

  const auth_state = pocket_auth_username && pocket_auth_access_token
    ? AUTH_STATES.AUTHORIZED
    : pocket_auth_request_token
      ? (
          pocket_auth_access_requested
            ? AUTH_STATES.ACCESS_REQUESTED
            : AUTH_STATES.REQUEST_TOKEN
        )
      : AUTH_STATES.UNIDENTIFIED

  return auth_state
}

async function async_action_initialize({
  indexed_db_client,
  pocket_auth_action,
  update_module_state,
  pocket_auth_initialized,
}) {
  if (!indexed_db_client) throw new Error( 'cannot initialize pocket auth -- indexed db client not specified' )
  if (!update_module_state) throw new Error( 'cannot initialize pocket auth -- state update function not specified' )

  if (pocket_auth_initialized) throw new Error( 'cannot initialize pocket auth -- pocket auth already initialized' )
  if (pocket_auth_action) throw new Error( 'cannot initialize pocket auth -- another action is currently running' )

  // set current action: initialize
  update_module_state({ action: ACTIONS.INITIALIZE })

  const log_entry = [
    'action=initialize-hook hook=use-pocket-auth',
  ]

  // seed with credentials from disk
  try {
    const { transaction, transaction_completed_promise } = utils.indexed_db.create_transaction({
      client: indexed_db_client,
      databases: [ 'pocket-auth' ],
      permissions: utils.indexed_db.create_transaction.TRANSACTION_PERMISSIONS.READ_ONLY,
    })

    const pocket_auth_db = transaction.objectStore( 'pocket-auth' )
    const pocket_auth_keys = pocket_auth_db.getAllKeys()
    const pocket_auth_vals = pocket_auth_db.getAll()

    await transaction_completed_promise

    const pocket_auth_disk_wrapper = pocket_auth_keys.result.reduce(( map, key, index ) => {
      map[ key ] = pocket_auth_vals.result[ index ]
      return map
    }, {})

    if (!pocket_auth_disk_wrapper) throw new Error( 'no credentials found on disk' )

    const pocket_auth_on_disk = pocket_auth_disk_wrapper[ pocket_auth_keys.result[ 0 ] ]

    update_module_state({ user: pocket_auth_on_disk })

    const credentials_type = get_pocket_auth_state( pocket_auth_on_disk )
    const seed_type = credentials_type === AUTH_STATES.AUTHORIZED
      ? 'pocket-user'
      : credentials_type === AUTH_STATES.UNIDENTIFIED
        ? 'anonymous-user'
        : 'request-token'

    log_entry.push(
      'seeded=true',
      `seed=${ seed_type }`,
    )
  }

  // if seeding fails: set current user as unknown, then bail
  catch (seeding_error) {
    update_module_state({ user: {} })

    log_entry.push(
      'seeded=false',
      `error="${ seeding_error?.stack ?? seeding_error?.message ?? 'unspecified error' }"`,
    )
  }

  console.log( log_entry.join(' ') )

  update_module_state({
    action: null,
    initialized: true,
  })
}

async function async_action_get_request_token({
  indexed_db_client,
  pocket_auth_action,
  update_module_state,
}) {
  if (!update_module_state) throw new Error( 'cannot get request token -- module state update function not specified' )
  if (!indexed_db_client) throw new Error( 'cannot get request token -- indexed db client not specified' )
  if (pocket_auth_action) throw new Error( 'cannot get request token -- an action is already running' )

  update_module_state({ action: ACTIONS.GET_REQUEST_TOKEN })

  const log_entry = [
    'action=get-request-token',
  ]

  try {
    const get_request_token_response = await fetch( GET_REQUEST_TOKEN_URL )
    const get_request_token_payload = await get_request_token_response.json()
    const { request_token: received_request_token } = get_request_token_payload ?? {}

    if (!received_request_token) throw new Error( 'failed to get request token' )

    const { transaction, transaction_completed_promise } = utils.indexed_db.create_transaction({
      client: indexed_db_client,
      databases: [ 'pocket-auth' ],
      permissions: utils.indexed_db.create_transaction.TRANSACTION_PERMISSIONS.READWRITE,
    })

    const pocket_auth_db = transaction.objectStore( 'pocket-auth' )
    const pocket_auth_key = received_request_token

    pocket_auth_db.clear()
    pocket_auth_db.add({ request_token: received_request_token, access_requested: false }, pocket_auth_key )

    await transaction_completed_promise

    update_module_state({
      user: {
        request_token: received_request_token,
        access_requested: false,
      }
    })

    log_entry.push(
      'success=true',
    )
  }

  catch (get_request_token_error) {
    update_module_state({ user: null })

    log_entry.push(
      'success=false',
      `error="${ get_request_token_error?.stack ?? get_request_token_error?.message ?? 'unspecified error' }"`,
    )
  }

  console.log( log_entry.join(' ') )

  update_module_state({ action: null })
}

async function async_action_get_access_token({
  request_token,
  indexed_db_client,
  pocket_auth_action,
  update_module_state,
}) {
  if (!request_token) throw new Error( 'cannot access token -- request token not specified' )
  if (!indexed_db_client) throw new Error( 'cannot access token -- indexed db client not specified' )
  if (!update_module_state) throw new Error( 'cannot access token -- state update function not specified' )

  if (pocket_auth_action) throw new Error( 'cannot get access token -- an action is already running' )

  update_module_state({ action: ACTIONS.GET_ACCESS_TOKEN })

  const log_entry = [
    'action=get-access-token',
  ]

  try {
    const get_access_token_response = await fetch(
      GET_ACCESS_TOKEN_URL,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ request_token })
      }
    )

    const get_access_token_payload = await get_access_token_response.json()

    const { success: get_access_token_success, error: get_access_token_error, credentials } = get_access_token_payload
    if (!get_access_token_success) throw new Error( get_access_token_error )

    const { transaction, transaction_completed_promise } = utils.indexed_db.create_transaction({
      client: indexed_db_client,
      databases: [ 'pocket-auth' ],
      permissions: utils.indexed_db.create_transaction.TRANSACTION_PERMISSIONS.READWRITE,
    })

    const pocket_auth_db = transaction.objectStore( 'pocket-auth' )
    const pocket_auth_key = credentials.username

    pocket_auth_db.clear()
    pocket_auth_db.add( credentials, pocket_auth_key )

    await transaction_completed_promise

    update_module_state({ user: credentials })

    log_entry.push(
      'success=true',
      `username=${ credentials.username }`
    )
  }

  catch( get_access_token_error ) {
    // request token is most likely used ... delete it
    const { transaction, transaction_completed_promise } = utils.indexed_db.create_transaction({
      client: indexed_db_client,
      databases: [ 'pocket-auth' ],
      permissions: utils.indexed_db.create_transaction.TRANSACTION_PERMISSIONS.READWRITE,
    })

    const pocket_auth_db = transaction.objectStore( 'pocket-auth' )
    pocket_auth_db.clear()

    await transaction_completed_promise

    update_module_state({ user: null })

    log_entry.push(
      'success=false',
      `error="${ get_access_token_error?.stack ?? get_access_token_error?.message ?? 'unspecified error' }"`
    )
  }

  console.log( log_entry.join(' ') )

  update_module_state({ action: null })
}

async function async_action_send_user_to_pocket_oauth_start_url({
  request_token,
  indexed_db_client,
  pocket_auth_action,
  update_module_state,
}) {
  if (!indexed_db_client) throw new Error( 'cannot send user to pocket oauth start page -- indexed db client not specified' )
  if (!update_module_state) throw new Error( 'cannot send user to pocket oauth start page -- module state updater not specified' )
  if (!request_token) throw new Error( 'cannot send user to pocket oauth start page -- request token not specified' )

  if (pocket_auth_action) throw new Error( 'cannot send user to pocket oauth start page -- an action is already running' )

  // set action: send user to pocket auth
  update_module_state({ action: ACTIONS.SEND_USER_TO_POCKET_OAUTH })

  try {
    const { hostname, port, protocol } = window.location
    const redirect_url = hostname === 'localhost'
      ? hostname +':'+ port
      : hostname

    const { transaction, transaction_completed_promise } = utils.indexed_db.create_transaction({
      client: indexed_db_client,
      databases: [ 'pocket-auth' ],
      permissions: utils.indexed_db.create_transaction.TRANSACTION_PERMISSIONS.READWRITE,
    })

    const pocket_auth_db = transaction.objectStore( 'pocket-auth' )
    const pocket_auth_key = request_token

    pocket_auth_db.clear()
    pocket_auth_db.add({ request_token: request_token, access_requested: true }, pocket_auth_key )

    await transaction_completed_promise

    window.location = `https://getpocket.com/auth/authorize?request_token=${ request_token }&redirect_uri=${ protocol +'//'+ redirect_url }`
  }

  catch( send_user_error ) {
    console.log( `action=send-user-to-pocket-oauth success=false error="${ send_user_error.stack ?? send_user_error.message ?? 'unspecified error' }"` )
  }

  update_module_state({ action: null })
}

async function async_action_log_out({
  auth_state,
  indexed_db_client,
  pocket_auth_user,
  pocket_auth_action,
  update_module_state,
}) {
  if (pocket_auth_action) throw new Error( 'cannot log out -- an action is already running' )
  if (auth_state !== AUTH_STATES.AUTHORIZED) throw new Error( 'no user is signed in' )
  if (!pocket_auth_user) throw new Error( 'user to log out not specified' )

  update_module_state({ action: ACTIONS.LOGOUT })

  const log_entry = [
    'action=logout',
    `user=${ pocket_auth_user.username }`,
  ]

  try {
    const { transaction, transaction_completed_promise } = utils.indexed_db.create_transaction({
      client: indexed_db_client,
      databases: [ 'pocket-auth' ],
      permissions: utils.indexed_db.create_transaction.TRANSACTION_PERMISSIONS.READWRITE,
    })

    const pocket_auth_db = transaction.objectStore( 'pocket-auth' )
    pocket_auth_db.clear()

    await transaction_completed_promise

    update_module_state({ user: null })

    log_entry.push( 'success=true' )
  }

  catch (logout_error) {

    log_entry.push(
      'success=false',
      `error="${ logout_error?.stack ?? logout_error?.message ?? 'unspecified error' }"`
    )
  }

  console.log( log_entry.join(' ') )

  update_module_state({ action: null })
}

async function async_handle_auth_state({
  auth_state,
  pocket_auth_action,
  pocket_auth_initialized,
  last_handled_auth_state,
  update_module_state,
  async_action_get_access_token,
  async_action_send_user_to_pocket_oauth_start_url,
}) {
  if (!async_action_send_user_to_pocket_oauth_start_url) throw new Error( 'unable to handle auth state -- function to send user to pocket oauth start page not specified' )
  if (!async_action_get_access_token) throw new Error( 'unable to handle auth state -- function to sget access token not specified' )
  if (!pocket_auth_initialized) throw new Error( 'unable to handle auth state -- pocket auth module has not yet been initialized' )

  if (auth_state === last_handled_auth_state) throw new Error( 'unable to handle auth state -- current state has already been handled' )
  if (pocket_auth_action) throw new Error( 'unable to handle auth state -- another action is currently running' )

  switch (auth_state) {

    case AUTH_STATES.REQUEST_TOKEN:
      await async_action_send_user_to_pocket_oauth_start_url()
      break

    case AUTH_STATES.ACCESS_REQUESTED:
      await async_action_get_access_token()
      break

    case AUTH_STATES.UNIDENTIFIED:
    case AUTH_STATES.AUTHORIZED:
      // do nothing, these states don't need to be handled
      break

    default:
      throw new Error( `no handler implemented for auth state "${ auth_state }"` )
  }

  update_module_state({ last_handled_auth_state: auth_state })
}
