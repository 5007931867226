import ExpandedViewLabel from '../ExpandedViewLabel'

import './style.css'

const ExpandedViewItemGroup = ({ className, title, children }) => {
  return <>
    <div className={ `ExpandedViewItemGroup${ className ? ` ${ className }` : '' }` }>
      {
        title && (
          <ExpandedViewLabel title={ title } />
        )
      }
      { children }
    </div>
  </>
}

export default ExpandedViewItemGroup
