import schema_v1 from './schemas/1'
import schema_v2 from './schemas/2'
import schema_v3 from './schemas/3'

const schemas = {
  '1': schema_v1,
  '2': schema_v2,
  '3': schema_v3,
}

export default schemas
