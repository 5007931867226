import { useState } from 'react'
import ReactTimeAgo from 'react-time-ago'

import ExpandedViewItem from '../ExpandedViewItem'

import { ReactComponent as MegaphoneIconSVG } from '../../svgs/megaphone-icon.svg'
import { ReactComponent as InternetArchiveIconSVG } from '../../svgs/internet-archive-icon.svg'
import { ReactComponent as GlobeIconSVG } from '../../svgs/globe-icon.svg'
import { ReactComponent as ReadInPocketIconSVG } from '../../svgs/read-in-pocket-icon.svg'
import { ReactComponent as TriangleUpSVG } from '../../svgs/triangle-point-up-icon.svg'
import { ReactComponent as TriangleDownSVG } from '../../svgs/triangle-point-down-icon.svg'
import { ReactComponent as FavoriteSVG } from '../../svgs/favorite-icon.svg'

import './style.css'

const time_stats_map = {
  time_added: {
    key: 'time_added',
    label: 'saved',
  },
  time_favorited: {
    key: 'time_favorited',
    label: 'favorited',
  },
  time_read: {
    key: 'time_read',
    label: 'archived',
  },
}

export default PocketContent

function PocketContent({ save, user_state, domain, images, videos, tags }){
  const [ isExpanded, setIsExpanded ] = useState( false )

  const parsed_by_pocket = save.resolved_url ? true : false

  const favorited = user_state.favorite === '1'

  const display_title = (
    save.resolved_title?.length > 0
      ? save.resolved_title
      : save.given_title?.length > 0
        ? save.given_title
        : save.resolved_url
  )

  const title_has_spaces = display_title && display_title.indexOf(' ') > -1

  const save_url = new URL(
    parsed_by_pocket
      ? save.resolved_url
      : save.given_url
  )

  const has_pdf_extension = (
    save_url
      .pathname
      .indexOf( '.pdf' ) === (save_url.pathname.length - 4)
  )

  let display_domain
  if (domain) display_domain = domain.name
  else {
    const domain_hostname = new URL( save_url ).hostname

    display_domain = (
      domain_hostname.indexOf( 'www.' ) === 0
        ? domain_hostname.replace( 'www.', '' )
        : domain_hostname
    )

    display_domain = (
      display_domain.indexOf( 'blog.' ) === 0
        ? display_domain.replace( 'blog.', '' )
        : display_domain
    )
  }

  const time_stats_to_display = Object
    .keys( time_stats_map )
    .filter( stat_key => user_state?.[ stat_key ] && user_state[ stat_key ] !== '0' )

  const time_stats_to_display_map = time_stats_to_display
    .reduce(( map, stat_key ) => {
      map[ stat_key ] = parseInt( user_state[ stat_key ] ) * 1000
      return map
    }, {})

  time_stats_to_display.sort(( stat_a, stat_b ) => {
    const stat_a_timestamp = time_stats_to_display_map[ stat_a ]
    const stat_b_timestamp = time_stats_to_display_map[ stat_b ]

    if (stat_a_timestamp < stat_b_timestamp) return -1
    if (stat_a_timestamp > stat_b_timestamp) return 1
    return 0
  })

  return (
    <div className='PocketContent'>
      <div key='header' className='Header'>
        {
          favorited && (
            <span key='favorited' style={{ position: 'absolute', marginLeft: '-2.25em', marginRight: '0.85em', verticalAlign: 'middle' }}>
              <FavoriteSVG height=".85em" fill="#aaa" />
            </span>
          )
        }
        <div key='display-title' className={ 'Title' + (!title_has_spaces ? ' spaceless-title' : '' ) }>{ display_title }</div>
      </div>
      <div key='metadata' className='Metadata'>
        {
          display_domain && (
            <span key='display-domain' className='Domain MetadataItem'>{ display_domain }</span>
          )
        }
        {
          !parsed_by_pocket && (
            <>
              { ' ' }
              <span key='pocket-not-parsed' className='NotParsed MetadataItem'>waiting for pocket to scan</span>
            </>
          )
        }
        {
          parsed_by_pocket && (
            <>
              { ' ' }
              <span key='wordcount' className={ `WordCount MetadataItem${ save.word_count === '0' ? ' no-words' : '' }` }>
                {
                  save.word_count === '0'
                    ? 'empty in pocket'
                    : save.word_count === '1'
                      ? '1 word'
                      : `${ save.word_count } words`
                }
              </span>
            </>
          )
        }
        {
          has_pdf_extension && (
            <>
              { ' ' }
              <span key='pdf-extension' className='PdfExtension MetadataItem'>pdf</span>
            </>
          )
        }
        {
          images && images.length > 0 && (
            <>
              { ' ' }
              <span key='image-count' className='Images MetadataItem'>
                {
                  images.length === 1
                    ? '1 image'
                    : `${ images.length } images`
                }
              </span>
            </>
          )
        }
        {
          videos && videos.length > 0 && (
            <>
              { ' ' }
              <span key='video-count' className='Videos MetadataItem'>
                {
                  videos.length === 1
                    ? '1 video'
                    : `${ videos.length } videos`
                }
              </span>
            </>
          )
        }
        {
          time_stats_to_display
            .map( stat_key => {
              const label = time_stats_map[ stat_key ].label
              const date = new Date( time_stats_to_display_map[ stat_key ] )
              const stat_class = `${ label[ 0 ].toUpperCase() }${ label.slice( 1 ) }`

              return (
                <span key={ stat_key } className={ `${ stat_class } MetadataItem` }>
                  { ' ' }
                  { label }
                  { ' ' }
                  <ReactTimeAgo
                    date={ date }
                    component={ ({ children }) => children.replace( ' ago', '' ) }
                  />
                </span>
              )
            })
        }
      </div>
      {
        tags
        && tags.length
        && tags.length > 0
        && (
          <div className='Tags' key='tags'>
            {
              tags.sort().map( tag => {
                return <span key={ tag } className='Tag'>
                  { tag }
                </span>
              })
            }
          </div>
        )
      }
      {
        isExpanded && (
          <>
            <div className='Excerpt' key='excerpt'>
              <p>{ save.excerpt }</p>
            </div>
            {
              images
              && images.length
              && images.length > 0
              && (
                <div className='Images' key='images'>
                  {
                    images.sort().map( image => {
                      return (
                        <a
                          href={ image.src }
                          target='_blank'
                          rel='noreferrer'
                          key={ `${ image.content_id }-${ image.content_image_id }` }
                        >
                          <div
                            style={{
                              backgroundImage: `url(${ image.src })`,
                              backgroundSize: 'cover',
                              width: '8em',
                              height: '4em',
                              marginRight: '1em',
                              marginBottom: '1em',
                              backgroundColor: '#eee',
                              backgroundPosition: 'center',
                              backgroundRepeat: 'no-repeat',
                              display: 'inline-block',
                              float: 'left',
                            }}
                          />
                        </a>
                      )
                    })
                  }
                </div>
              )
            }
          </>
        )
      }
      <div className='Actions' key='actions'>
        <span
          className='ShareLink Action'
          onClick={() => {
            navigator.share({
              title: display_title,
              text: display_title,
              url: save.given_url,
            })
          }}
          style={{
            textDecorationLine: 'underline',
            textDecorationThickness: '1px',
            textDecorationColor: 'orange',
            cursor: 'pointer',
          }}
        >
          <span className="InlineIcon">
            <MegaphoneIconSVG />
          </span>
          <span className="Label">share</span>
        </span>
        <a
          className='OpenWaybackMachine Action'
          href={
            'https://web.archive.org/web/*/'
            + save.given_url
          }
          target='_blank'
          rel='noreferrer'
        >
          <span className="InlineIcon">
            <InternetArchiveIconSVG />
          </span>
          <span className="Label">wayback</span>
        </a>
        <a
          className='OpenSource Action'
          href={ save.given_url }
          target='_blank'
          rel='noreferrer'
        >
          <span className="InlineIcon">
            <GlobeIconSVG />
          </span>
          <span className="Label">web</span>
        </a>
        {
          parsed_by_pocket
          && (
            <>
              <a
                className='ReadInPocket Action'
                href={ `https://getpocket.com/app/read/${ save.item_id }` }
                target='_blank'
                rel='noreferrer'
              >
                <span className="InlineIcon TallIcon">
                  <ReadInPocketIconSVG />
                </span>
                <span className="Label">pocket</span>
              </a>
            </>
          )
        }
        {
          (
            save.excerpt?.length > 0
            || images?.length > 0
          )
          && (
            <ExpandedViewItem
              icon={
                isExpanded
                  ? <span style={{ verticalAlign: 'middle' }}>
                      <TriangleUpSVG fill="#aaa" height=".85em" width=".85em" />
                    </span>
                  : <span style={{ verticalAlign: 'middle' }}>
                      <TriangleDownSVG fill="#aaa" height=".85em" width=".85em" />
                    </span>
              }
              label={
                isExpanded
                  ? 'less'
                  : 'more'
              }
              onClick={ () => setIsExpanded( !isExpanded ) }
            />
          )
        }
      </div>
    </div>
  )
}
