import { useCallback, useEffect } from 'react'
import useIndexedDB from '../useIndexedDB'

import appContext from '../../context'
import utils from '../../utils'

export const VALID_LOCATIONS = {
  MEMORY: 'memory',
  DISK: 'disk',
  API: 'api',
}

export const VALID_GET_LOCATIONS = Object
  .keys( VALID_LOCATIONS )
  .map( key => VALID_LOCATIONS[ key ] )

export const VALID_SAVE_LOCATIONS = [
  VALID_LOCATIONS.MEMORY,
  VALID_LOCATIONS.DISK,
]

export const ACTIONS = {
  INITIALIZE: 'ACTION_INITIALIZE',
  START_SYNC: 'ACTION_START_SYNC',
  SWITCH_TO_NEXT_SYNC: 'ACTION_SWITCH_TO_NEXT_SYNC',
}

const SEC_IN_MS = 1000
const MIN_IN_MS = 60 * SEC_IN_MS
const APP_CONTEXT_MODULE_ID = 'sync_status'

export default useUserSyncStatus

const API_URL = process.env.REACT_APP_SPARKPOCKETJOY_API_URL
if (!API_URL) throw new Error( 'api url not specified' )

const { useAppContext } = appContext

function useUserSyncStatus() {
  useIndexedDB()

  const [ app_context, set_app_context ] = useAppContext()
  const {
    indexed_db_client,

    username,
    access_token,

    sync_status_module,
    sync_status_initialized,
    sync_status_action,
    sync_status_module_actions,
    sync_status_wrapper,
    current_sync_status,
    next_sync_status,
    daily_pocket_sync,
  } =
    utils.app_context.pluck({
      app_context,
      keys_to_pluck: [
        'indexed_db_client',

        'username',
        'access_token',

        'sync_status_module',
        'sync_status_initialized',
        'sync_status_module_actions',
        'sync_status_action',
        'sync_status_wrapper',
        'current_sync_status',
        'next_sync_status',
        'daily_pocket_sync',
      ],
    })

  // bind functions to state with useCallback
  // ===

  const callback_create_sync_status_module = useCallback(() => {
    return utils.app_context.create_module({
      module_id: APP_CONTEXT_MODULE_ID,
      set_app_context,
      app_context,
    })
  }, [
    set_app_context,
    app_context,
  ])

  const callback_update_module_state = useCallback( state_updates => {
    return utils.app_context.update_module_state({
      module_id: APP_CONTEXT_MODULE_ID,
      set_app_context,
      app_context,
      state_updates,
    })
  }, [
    set_app_context,
    app_context,
  ])

  const callback_set_module_actions = useCallback( ({ actions }) => {
    return utils.app_context.set_module_actions({
      module_id: APP_CONTEXT_MODULE_ID,
      set_app_context,
      app_context,
      actions,
    })
  }, [
    set_app_context,
    app_context,
  ])

  const async_callback_get_user_sync_status_from_disk = useCallback( async () => {
    return await async_get_user_sync_status_from_disk({
      indexed_db_client,
      username,
    })
  }, [
    indexed_db_client,
    username,
  ])

  const async_callback_save_user_sync_status = useCallback( async ({ sync_status, locations } = {}) => {
    return await async_save_user_sync_status({
      username,
      indexed_db_client,
      update_module_state: callback_update_module_state,
      sync_status,
      locations,
    })
  }, [
    username,
    indexed_db_client,
    callback_update_module_state,
  ])

  const async_callback_action_initialize = useCallback( async () => {
    return await async_action_initialize({
      sync_status_module,
      sync_status_initialized,
      async_get_user_sync_status_from_disk: async_callback_get_user_sync_status_from_disk,
      async_save_user_sync_status: async_callback_save_user_sync_status,
      sync_status_action,
      update_module_state: callback_update_module_state,
    })
  }, [
    sync_status_module,
    sync_status_initialized,
    async_callback_get_user_sync_status_from_disk,
    async_callback_save_user_sync_status,
    sync_status_action,
    callback_update_module_state,
  ])

  const async_callback_clear_user_sync_status = useCallback( async () => {
    return await async_clear_user_sync_status({
      username,
      sync_status_wrapper,
      async_save_user_sync_status: async_callback_save_user_sync_status,
    })
  }, [
    username,
    sync_status_wrapper,
    async_callback_save_user_sync_status,
  ])

  const async_callback_action_switch_to_next_sync = useCallback( async () => {
    return await async_action_switch_to_next_sync({
      next_sync_status,
      async_save_user_sync_status: async_callback_save_user_sync_status,
      update_module_state: callback_update_module_state,
      sync_status_wrapper,
      sync_status_action,
    })
  }, [
    next_sync_status,
    callback_update_module_state,
    async_callback_save_user_sync_status,
    sync_status_wrapper,
    sync_status_action,
  ])

  const async_callback_get_daily_pocket_sync_details_from_api = useCallback( async () => {
    return await async_get_daily_pocket_sync_details_from_api({
      username,
      access_token,
      sync_status_wrapper,
      async_save_user_sync_status: async_callback_save_user_sync_status,
    })
  }, [
    username,
    access_token,
    sync_status_wrapper,
    async_callback_save_user_sync_status,
  ])

  const async_callback_action_start_sync = useCallback( async () => {
    return await async_action_start_sync({
      username,
      access_token,
      sync_status_wrapper,
      current_sync_status,
      update_module_state: callback_update_module_state,
      async_save_user_sync_status: async_callback_save_user_sync_status,
      sync_status_action,
      async_get_daily_pocket_sync_details_from_api: async_callback_get_daily_pocket_sync_details_from_api,
    })
  }, [
    username,
    access_token,
    sync_status_wrapper,
    current_sync_status,
    callback_update_module_state,
    async_callback_save_user_sync_status,
    sync_status_action,
    async_callback_get_daily_pocket_sync_details_from_api,
  ])

  const async_callback_update_daily_sync_availability = useCallback( async () => {
    return await async_update_daily_sync_availability({
      update_module_state: callback_update_module_state,
      daily_pocket_sync,
      daily_sync_available: sync_status_module?.state?.daily_sync_available,
    })
  }, [
    callback_update_module_state,
    daily_pocket_sync,
    sync_status_module?.state?.daily_sync_available,
  ])


  // setup effects
  // ===

  // 1. create user sync status module if it doesn't exist
  useEffect(() => {
    if (sync_status_module) return

    callback_create_sync_status_module()
  }, [
    sync_status_module,
    callback_create_sync_status_module,
  ])

  // 2. if hook isn't initialized and no action is running, initialize
  useEffect(() => {
    if (!indexed_db_client || !username || !sync_status_module) return
    if (sync_status_initialized || sync_status_action) return

    async_callback_action_initialize()
  }, [
    indexed_db_client,
    username,
    sync_status_module,
    sync_status_initialized,
    sync_status_action,
    async_callback_action_initialize,
  ])

  // 3. set available actions
  const sync_status_module_action_keys = sync_status_module_actions
    ? Object.keys( sync_status_module_actions )
    : []
  const stringified_module_action_keys = JSON.stringify( sync_status_module_action_keys )

  useEffect(() => {
    if (!sync_status_initialized) return
    if (!stringified_module_action_keys) return

    const sync_status_module_action_keys = JSON.parse( stringified_module_action_keys )

    if (next_sync_status?.sync_id || sync_status_action) {
      if (sync_status_module_action_keys.length === 0) return;

      callback_set_module_actions({ actions: {} })
    }

    else {
      if (
        sync_status_module_action_keys.indexOf( 'async_start_sync' ) === -1
        || (current_sync_status?.sync_id && sync_status_module_action_keys.indexOf( 'async_clear_sync' ) === -1)
        || (!current_sync_status?.sync_id && sync_status_module_action_keys.indexOf( 'async_clear_sync' ) > -1)
      )

      callback_set_module_actions({
        actions: {
          async_start_sync: async_callback_action_start_sync,
          ...( current_sync_status?.sync_id
            ? { async_clear_sync: async_callback_clear_user_sync_status }
            : {}
          ),
        }
      })
    }
  }, [
    sync_status_initialized,

    next_sync_status?.sync_id,
    current_sync_status?.sync_id,

    username,
    access_token,
    sync_status_action,

    app_context?.last_state_modified,

    stringified_module_action_keys,
    callback_set_module_actions,
    async_callback_action_start_sync,
    async_callback_clear_user_sync_status,
  ])

  // 4. clear stored (in-memory and on-disk) sync status if logged-in user isn't owner
  useEffect(() => {
    if (!sync_status_initialized || !current_sync_status || !username) return

    const { username: sync_owner } = current_sync_status
    if (!sync_owner || (username === sync_owner)) return

    async_callback_clear_user_sync_status()
  }, [
    sync_status_initialized,
    current_sync_status,
    username,
    async_callback_clear_user_sync_status,
  ])

  // 5. check for updates if next sync status is queued or has already started syncing
  const stringified_sync_status_wrapper = JSON.stringify( sync_status_wrapper )
  useEffect(() => {
    const sync_status_wrapper = stringified_sync_status_wrapper && JSON.parse( stringified_sync_status_wrapper )
    const { next: next_sync_status, ...sync_status_wrapper_minus_next_sync } = sync_status_wrapper ?? {}

    if (!sync_status_initialized || !username || !access_token || !next_sync_status) return
    if (!next_sync_status.sync_id || ( next_sync_status.status !== 'queued' && next_sync_status.status !== 'syncing' )) return

    const check_interval_s =
      next_sync_status.status === 'queued'
        ? 3
        : next_sync_status.status === 'syncing'
          ? .5
          : NaN

    if (Number.isNaN( check_interval_s )) throw new Error( 'expected check interval duration to be a number, but it isn\'t' )

    const status_checker = setInterval(() => {
      async_get_sync_status()
      async function async_get_sync_status() {
        let updated_sync_status

        try {
          updated_sync_status = await async_get_user_sync_status_from_api({
            username,
            access_token,
            sync_id: next_sync_status.sync_id
          })

          await async_callback_save_user_sync_status({
            sync_status: {
              ...sync_status_wrapper_minus_next_sync,
              next: updated_sync_status,
            }
          })
        }

        catch (get_update_error) {
          console.log( 'TODO: debounce attempts to get user sync status after a failed attempt, to prevent stampedes on the server' )
          console.log( `action=get-user-sync-status success=false source=api error="${ get_update_error?.stack ?? get_update_error?.message ?? 'unspecified error' }"` )
        }
      }
    }, check_interval_s * SEC_IN_MS)

    const cancel_status_checker = () => {
      clearInterval( status_checker )
    }

    return cancel_status_checker
  }, [
    sync_status_initialized,
    username,
    access_token,
    indexed_db_client,
    stringified_sync_status_wrapper,
    async_callback_save_user_sync_status,
  ])

  // 6. when next sync completes, convert it to current sync
  useEffect(() => {
    if (!next_sync_status || next_sync_status.status !== 'completed') return
    if (sync_status_action) return

    async_callback_action_switch_to_next_sync()
  }, [
    sync_status_action,
    next_sync_status,
    async_callback_action_switch_to_next_sync,
  ])

  // 7. get daily sync details, if not found
  useEffect(() => {
    if (!sync_status_initialized) return
    if (daily_pocket_sync || daily_pocket_sync === null) return

    async_callback_get_daily_pocket_sync_details_from_api()
  }, [
    sync_status_initialized,
    daily_pocket_sync,
    async_callback_get_daily_pocket_sync_details_from_api,
  ])

  // 8. manage daily sync availability
  useEffect(() => {
    if (!sync_status_initialized) return
    if (!daily_pocket_sync && daily_pocket_sync !== null) return

    async_callback_update_daily_sync_availability()
  }, [
    sync_status_initialized,
    daily_pocket_sync,
    async_callback_update_daily_sync_availability,
  ])

  // setup daily pocket sync periodic checker
  useEffect(() => {
    if (!sync_status_initialized) return

    const daily_sync_available = sync_status_module?.state?.daily_sync_available
    if (daily_sync_available !== true && daily_sync_available !== false) return

    switch (daily_sync_available) {
      case true:
        {
          const checker_timeout = setTimeout(
            async_callback_get_daily_pocket_sync_details_from_api,
            5 * MIN_IN_MS,
          )

          return () => {
            clearTimeout( checker_timeout )
          }
        }

      case false:
      default:
        {
          const daily_pocket_sync_epoch = (new Date( daily_pocket_sync )).getTime()
          const next_available_sync_epoch = new Date( daily_pocket_sync_epoch + ( 15 * MIN_IN_MS ))
          const now_epoch = (new Date()).getTime()

          const time_til_next_sync_ms = next_available_sync_epoch - now_epoch

          if (time_til_next_sync_ms <= 0) {
            async_callback_update_daily_sync_availability()
            return
          }

          const checker_timeout = setTimeout(
            async_callback_update_daily_sync_availability,
            time_til_next_sync_ms,
          )

          return () => {
            clearTimeout( checker_timeout )
          }
        }
    }
  }, [
    sync_status_initialized,
    daily_pocket_sync,
    sync_status_module?.state?.daily_sync_available,
    async_callback_get_daily_pocket_sync_details_from_api,
    async_callback_update_daily_sync_availability,

  ])

  return app_context?.[ APP_CONTEXT_MODULE_ID ]
}

async function async_action_initialize({
  sync_status_module,
  sync_status_initialized,
  async_get_user_sync_status_from_disk,
  async_save_user_sync_status,
  sync_status_action,
  update_module_state,
}) {
  const error_msg_prefix = `cannot initialize module "${ APP_CONTEXT_MODULE_ID }"`

  if (!sync_status_module) throw new Error( `${ error_msg_prefix } -- module not found` )
  if (!async_get_user_sync_status_from_disk) throw new Error( `${ error_msg_prefix } -- function to get sync status from disk not found` )
  if (!async_save_user_sync_status) throw new Error( `${ error_msg_prefix } -- function to save sync status in memory not found` )

  if (sync_status_initialized) throw new Error( `${ error_msg_prefix } -- module is already initialized` )
  if (sync_status_action) throw new Error( `${ error_msg_prefix } -- another action is currently running` )

  update_module_state({ action: ACTIONS.INITIALIZE })

  const log_entry = [
    'action=initialize-hook hook=use-user-sync-status',
  ]

  // load sync status from disk
  try {
    const sync_status_to_seed = await async_get_user_sync_status_from_disk()

    await async_save_user_sync_status({
      sync_status: sync_status_to_seed,
      locations: [ VALID_LOCATIONS.MEMORY ],
    })

    log_entry.push(
      'success=true',
      'seeded=true',
      'source=disk',
    )
  }

  catch (seed_from_disk_error) {
    log_entry.push(
      'success=true',
      'seeded=false',
      `seed_error="${ seed_from_disk_error?.stack ?? seed_from_disk_error?.message ?? 'unspecified' }"`
    )
  }

  console.log( log_entry.join(' ') )

  update_module_state({ initialized: true, action: null })
}

async function async_action_start_sync({
  username,
  access_token,
  current_sync_status,
  sync_status_wrapper = {},
  update_module_state,
  async_save_user_sync_status,
  sync_status_action,
  async_get_daily_pocket_sync_details_from_api,
}) {
  const error_msg_prefix = 'cannot start sync with pocket'

  if (!username) throw new Error( `${ error_msg_prefix } -- username not found` )
  if (!access_token) throw new Error( `${ error_msg_prefix } -- access token not found` )
  if (!update_module_state) throw new Error( `${ error_msg_prefix } -- module state update function not found` )
  if (!async_save_user_sync_status) throw new Error( `${ error_msg_prefix } -- save user sync status function not found` )

  if (sync_status_action) throw new Error( 'cannot start sync with pocket -- another action is currently running' )

  update_module_state({ action: ACTIONS.START_SYNC })

  const log_entry = [
    'action=start-sync-user-content',
  ]

  try {
    const start_sync_response = await fetch(
      API_URL + '/sync-user-content',
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          access_token,
          username,
          feature: 'daily_pocket_sync',
          timezone_offset: (new Date()).getTimezoneOffset() / 60,
          ...(
            current_sync_status
            && current_sync_status.sync_id
            && current_sync_status.catch_up_code
              ? {
                  catch_up_code: current_sync_status.catch_up_code,
                  source_sync_id: current_sync_status.sync_id,
                }
              : {}
          ),
        })
      }
    )

    const start_sync_result = await start_sync_response.json()
    const {
      success: request_success,
      error: request_error,
      error_code: request_error_code,
      sync_status: received_sync_status,
    } = start_sync_result

    if (!request_success) {

      switch (request_error_code) {
        case 'ERR_SYNC_LOCKED':
          async_get_daily_pocket_sync_details_from_api()
          break

        default:
          // do nothing if error code is not recognised
          break
      }

      throw new Error( request_error ?? 'unspecified error encountered while requesting a sync with pocket' )
    }

    await async_save_user_sync_status({
      sync_status: {
        ...sync_status_wrapper,
        next: received_sync_status,
      }
    })

    log_entry.push(
      'success=true'
    )
  }

  catch (start_sync_error) {
    log_entry.push(
      'success=false',
      `error="${ start_sync_error?.stack ?? start_sync_error?.message ?? 'unspecified error' }"`
    )
  }

  console.log( log_entry.join(' ') )

  update_module_state({ action: null })
}

async function async_action_switch_to_next_sync({
  next_sync_status,
  async_save_user_sync_status,
  update_module_state,
  sync_status_wrapper,
  sync_status_action,
}) {
  const error_msg_prefix = 'cannot switch to next sync status'

  if (!next_sync_status) throw new Error( `${ error_msg_prefix } -- next sync status not found` )
  if (!async_save_user_sync_status) throw new Error( `${ error_msg_prefix } -- function to save sync status not found` )
  if (!update_module_state) throw new Error( `${ error_msg_prefix } -- module state update function not found` )

  if (sync_status_action) throw new Error( `${ error_msg_prefix } -- another action is currently running` )

  update_module_state({ action: ACTIONS.SWITCH_TO_NEXT_SYNC })

  try {
    const { next, ...sync_status_minus_next } = sync_status_wrapper

    await async_save_user_sync_status({
      sync_status: {
        ...sync_status_minus_next,
        current: next_sync_status,
      }
    })
  }

  catch (switch_sync_status_error) {
    console.log( `action=switch-to-next-sync success=false error="${ switch_sync_status_error.stack ?? switch_sync_status_error.message }"` )
  }

  update_module_state({ action: null })
}

async function async_get_user_sync_status_from_disk({
  indexed_db_client,
  username,
  key: database_name = 'sync-status',
} = {}) {
  if (!indexed_db_client) throw new Error( 'indexed-db client not found' )
  if (!username) throw new Error( 'in-memory username not found' )

  const { transaction, transaction_completed_promise } = utils.indexed_db.create_transaction({
    client: indexed_db_client,
    databases: [ database_name ],
    permissions: utils.indexed_db.create_transaction.TRANSACTION_PERMISSIONS.READ_ONLY,
  })

  const sync_status_db = transaction.objectStore( database_name )
  const get_user_on_disk_sync_status = sync_status_db.get( username )

  await transaction_completed_promise

  if (!get_user_on_disk_sync_status.result) throw new Error( `no sync status found on disk for user "${ username }"` )

  const {
    username: on_disk_owner_username,
    sync_status: on_disk_sync_status,
  } = get_user_on_disk_sync_status.result

  if (on_disk_owner_username !== username) throw new Error( `no sync status found on disk for user "${ username }"` )

  return on_disk_sync_status
}

async function async_get_user_sync_status_from_api({
  username,
  access_token,
  sync_id,
} = {}) {
  if (!username || !access_token) throw new Error( 'malformed/missing pocket credentials' )
  if (!sync_id) throw new Error( 'malformed/missing sync id' )

  const request_payload = {
    access_token,
    username,
    sync_id,
  }

  const responseRaw = await fetch(
    API_URL + '/get-user-sync-status',
    {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify( request_payload ),
    }
  )

  const response = await responseRaw.json()
  const { success, sync_status: response_sync_status } = response
  const sync_status_to_use = typeof response_sync_status === 'object' ? response_sync_status : {}

  if (!success) throw new Error( response.error ?? 'unspecified error' )

  return sync_status_to_use
}

async function async_save_user_sync_status({
  disk_key = 'sync-status',
  username,
  indexed_db_client,
  update_module_state,
  locations = VALID_SAVE_LOCATIONS,
  sync_status: sync_status_to_save,
} = {}){
  const error_msg_prefix = 'cannot save user sync status'

  if (locations.includes( VALID_LOCATIONS.MEMORY )) {
    if (!update_module_state) throw new Error( `${ error_msg_prefix } -- module state update function not found` )

    update_module_state({ sync_status: sync_status_to_save })

    console.log( 'action=save-sync-status location=memory success=true' )
  }

  if (locations.includes( VALID_LOCATIONS.DISK )) {
    if (!username) throw new Error( `${ error_msg_prefix } -- username not found` )
    if (!indexed_db_client) throw new Error( `${ error_msg_prefix } -- indexed-db client not found` )

    const sync_status_disk_wrapper = {
      username,
      sync_status: sync_status_to_save,
    }

    const {
      transaction,
      transaction_completed_promise,
    } = utils.indexed_db.create_transaction({
      client: indexed_db_client,
      databases: [ disk_key ],
      permissions: utils.indexed_db.create_transaction.TRANSACTION_PERMISSIONS.READWRITE,
    })

    const sync_status_db = transaction.objectStore( disk_key )

    sync_status_db.put( sync_status_disk_wrapper, username )

    await transaction_completed_promise

    console.log( `action=save-sync-status location=disk success=true username=${ username }` )
  }
}

async function async_clear_user_sync_status({
  username,
  sync_status_wrapper = {},
  async_save_user_sync_status,
} = {}) {
  if (!username) throw new Error( 'username not found' )
  if (!async_save_user_sync_status) throw new Error( 'function to save sync status not found' )

  const { current, next, ...sync_status_wrapper_minus_current_and_next } = sync_status_wrapper

  await async_save_user_sync_status({
    sync_status: sync_status_wrapper_minus_current_and_next,
  })

  console.log( `action=clear-user-sync-status success=true user=${ username }` )
}

async function async_get_daily_pocket_sync_details_from_api({
  username,
  access_token,
  sync_status_wrapper = {},
  async_save_user_sync_status,
}) {
  if (!username) throw new Error( 'username not found' )
  if (!access_token) throw new Error( 'access token not found' )
  if (!async_save_user_sync_status) throw new Error( 'function to save sync status not found' )

  const request_payload = {
    access_token,
    username,
  }

  const responseRaw = await fetch(
    API_URL + '/get-user-daily-pocket-sync-details',
    {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify( request_payload ),
    }
  )

  const response = await responseRaw.json()
  const { success, daily_pocket_sync } = response

  if (!success) throw new Error( response.error ?? 'unspecified error' )

  await async_save_user_sync_status({
    sync_status: {
      ...sync_status_wrapper,
      daily_pocket_sync,
    },
  })

  return daily_pocket_sync
}

async function async_update_daily_sync_availability({
  update_module_state,
  daily_pocket_sync,
  daily_sync_available,
}) {
  if (!update_module_state) throw new Error( 'function to update module state not specified' )

  // no daily sync on record, mark feature as available
  if (daily_pocket_sync === null) {
    if (daily_sync_available !== true) update_module_state({ daily_sync_available: true })
    return
  }

  try {
    validate_daily_pocket_sync_details({ daily_pocket_sync })
  }

  catch (validation_error) {

    // delete invalid daily pocket sync
    update_module_state({ daily_sync_available: null })
    return
  }

  // set daily sync availability
  const daily_pocket_sync_date = new Date( daily_pocket_sync )
  const sync_available_date = new Date( daily_pocket_sync_date.getTime() + ( 1000 * 60 * 15 ) )
  const is_daily_sync_available = (new Date()).toISOString() > sync_available_date.toISOString()
    ? true
    : false

  if (daily_sync_available !== is_daily_sync_available) update_module_state({ daily_sync_available: is_daily_sync_available })
}

function validate_daily_pocket_sync_details({
  daily_pocket_sync,
}) {
  if (typeof daily_pocket_sync !== 'string' ) throw new Error( `unexpected datatype "${ typeof daily_pocket_sync }" for daily pocket sync` )

  const daily_pocket_sync_date = new Date( daily_pocket_sync )
  const now_date = new Date()
  const now_iso_string = now_date.toISOString()

  if (daily_pocket_sync_date > now_iso_string) throw new Error( 'sync date should not be newer than now' )

  return {
    daily_pocket_sync,
    date: daily_pocket_sync_date,
  }
}