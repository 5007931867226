import appContext from '../../context'
import utils from '../../utils'

import MainDisplayModule from '../../components/MainDisplayModule'
import ExpandedViewItemGroup from '../../components/ExpandedViewItemGroup'
import ExpandedViewItem from '../../components/ExpandedViewItem'

import useMainDisplayModuleHistory from '../../hooks/useMainDisplayModuleHistory'
import usePocketAuth from '../../hooks/usePocketAuth'

import { ReactComponent as UserSVG } from '../../svgs/user-icon.svg'
import { ReactComponent as LogoutSVG } from '../../svgs/logout-icon.svg'
import { ReactComponent as IDCardSVG } from '../../svgs/id-card-icon.svg'

import './style.css'

const { useAppContext } = appContext

const DISPLAY_MODULE_ID = 'PocketUser'

const PocketUser = () => {
  usePocketAuth()
  const [ appContextState ] = useAppContext()
  const {
    username,
    access_token,
    pocket_auth_action,
    pocket_auth_module_actions,
    display_history_initialized,
  } =
    utils.app_context.pluck({
      app_context: appContextState,
      keys_to_pluck: [
        'username',
        'access_token',
        'pocket_auth_action',
        'pocket_auth_module_actions',
        'display_history_initialized',
      ]
    })

  const { async_log_out } = pocket_auth_module_actions ?? {}

  const { get: get_history, set: set_history } = useMainDisplayModuleHistory()
  const display_history = get_history({ id: DISPLAY_MODULE_ID })
  const { expanded: module_expanded } = display_history ?? {}

  return (
    username && access_token && display_history_initialized
      ? <MainDisplayModule
          id="PocketUser"
          label="user"
          inline={
            <>
              { `${ username }` }
              { ' - ' }
              <span className="Action" onClick={ () => !pocket_auth_action && async_log_out() }>
                <span className="Icon">
                  <LogoutSVG />
                </span>
                <span className="Label">log out</span>
              </span>
            </>
          }
          expanded={
            <>
              <div className='Title'>
                <span className='Icon'>
                  <UserSVG />
                </span>
                <span className='Label'>
                  pocket user
                </span>
              </div>
              <ExpandedViewItemGroup title="about user">
                <ExpandedViewItem
                  icon={ <IDCardSVG /> }
                  label={ `${ username }` }
                />
              </ExpandedViewItemGroup>
              <ExpandedViewItemGroup title="actions">
                <ExpandedViewItem
                  icon={ <LogoutSVG fill='currentColor' /> }
                  label={ 'log out' }
                  onClick={ () => !pocket_auth_action && async_log_out() }
                />
              </ExpandedViewItemGroup>
            </>
          }
          initialIsInline={ !module_expanded }
          onDisplayHistoryUpdate={
            ({ expanded: updated_expanded }) => {
              set_history({ id: DISPLAY_MODULE_ID, expanded: updated_expanded })
            }
          }
        />
      : null
  )
}

export default PocketUser
