import { useCallback, useEffect } from 'react'
import useIndexedDB from '../useIndexedDB'

import appContext from '../../context'
import utils from '../../utils'

const { useAppContext } = appContext

const APP_CONTEXT_MODULE_ID = 'data_indexes'
const DISK_DATABASE_NAME = 'data-indexes'

const ACTIONS = {
  INITIALIZE: 'action_initialize',
  CREATE_DATA_INDEXES: 'action_create_data_indexes',
}

export default useDataIndexes

function useDataIndexes() {
  useIndexedDB()

  const [ app_context, set_app_context ] = useAppContext()
  const {
    indexed_db_client,

    sync_id,

    username,
    access_token,

    content_data,
    content_data_sync_id,

    data_indexes_module,
    data_indexes_action,
    data_indexes_sync_id,
    data_indexes_initialized,
    data_indexes_module_state,
  } =
    utils.app_context.pluck({
      app_context,
      keys_to_pluck: [
        'indexed_db_client',

        'sync_id',

        'username',
        'access_token',

        'content_data',
        'content_data_sync_id',

        'data_indexes_module',
        'data_indexes_action',
        'data_indexes_sync_id',
        'data_indexes_initialized',
        'data_indexes_module_state',
      ]
    })

  // bind functions to state with useCallback
  // ===

  const callback_create_data_indexes_module = useCallback(() => {
    return utils.app_context.create_module({
      module_id: APP_CONTEXT_MODULE_ID,
      set_app_context,
      app_context,
    })
  }, [
    set_app_context,
    app_context,
  ])

  const callback_update_module_state = useCallback( state_updates => {
    return utils.app_context.update_module_state({
      module_id: APP_CONTEXT_MODULE_ID,
      set_app_context,
      app_context,
      state_updates,
    })
  }, [
    set_app_context,
    app_context,
  ])

  const callback_save_indexes_to_memory = useCallback( ({ data_indexes, sync_id }) => {
    return save_data_indexes_to_memory({
      data_indexes,
      username,
      sync_id,
      callback_update_module_state,
    })
  }, [
    username,
    callback_update_module_state,
  ])

  const async_callback_load_indexes_from_disk = useCallback( async () => {
    return await async_load_indexes_from_disk({
      username,
      sync_id,
      indexed_db_client,
      callback_save_indexes_to_memory,
    })
  }, [
    username,
    sync_id,
    indexed_db_client,
    callback_save_indexes_to_memory,
  ])

  const async_callback_save_indexes_to_disk = useCallback( async ({ data_indexes, sync_id }) => {
    return await async_save_indexes_to_disk({
      indexed_db_client,
      username,
      sync_id,
      data_indexes,
    })
  }, [
    indexed_db_client,
    username,
  ])

  const async_callback_action_initialize = useCallback( async () => {
    return await async_action_initialize({
      data_indexes_module,
      callback_update_module_state,
      async_callback_load_indexes_from_disk,
      data_indexes_action,
    })
  }, [
    data_indexes_module,
    callback_update_module_state,
    async_callback_load_indexes_from_disk,
    data_indexes_action,
  ])

  const callback_delete_indexes_from_memory = useCallback(() => {
    return delete_indexes_from_memory({
      callback_update_module_state,
    })
  }, [
    callback_update_module_state,
  ])

  const async_callback_action_create_indexes = useCallback( async () => {
    return await async_action_create_indexes({
      data_indexes_sync_id,
      content_data_sync_id,
      content_data,
      async_callback_save_indexes_to_disk,
      callback_save_indexes_to_memory,
      callback_update_module_state,
      data_indexes_action,
    })
  }, [
    data_indexes_sync_id,
    content_data_sync_id,
    content_data,
    async_callback_save_indexes_to_disk,
    callback_save_indexes_to_memory,
    callback_update_module_state,
    data_indexes_action,
  ])

  // setup hook effects
  // ===

  // 1. create data indexes module if it doesn't exist
  useEffect(() => {
    if (data_indexes_module) return

    callback_create_data_indexes_module()
  }, [
    data_indexes_module,
    callback_create_data_indexes_module,
  ])

  // 2. initialize hook
  useEffect(() => {
    if (!sync_id || !username || !access_token || !indexed_db_client || !content_data_sync_id) return
    if (!data_indexes_module) return
    if (data_indexes_action || data_indexes_initialized) return

    async_callback_action_initialize()
  }, [
    content_data_sync_id,
    indexed_db_client,
    access_token,
    username,
    sync_id,
    data_indexes_module,
    data_indexes_initialized,
    data_indexes_action,
    async_callback_action_initialize,
  ])

  // 3. drop in-memory data indexes if logged-in user or sync id changes
  const { username: data_indexes_owner } = data_indexes_module_state ?? {}
  useEffect(() => {
    if (!data_indexes_owner || !data_indexes_sync_id) return

    if (
      data_indexes_owner === username
      && data_indexes_sync_id === content_data_sync_id
    ) return

    callback_delete_indexes_from_memory()
  }, [
    username,
    data_indexes_owner,
    data_indexes_sync_id,
    content_data_sync_id,
    callback_delete_indexes_from_memory,

    app_context?.last_state_modified,
  ])

  // 4. create data indexes if module is initialized yet no data indexes exist
  useEffect(() => {
    if (!indexed_db_client || !content_data_sync_id || !username || !access_token || !data_indexes_initialized) return
    if (data_indexes_sync_id === content_data_sync_id) return
    if (data_indexes_action) return

    async_callback_action_create_indexes()
  }, [
    data_indexes_initialized,
    data_indexes_sync_id,
    data_indexes_action,

    indexed_db_client,

    access_token,
    username,

    content_data_sync_id,

    async_callback_action_create_indexes,

    app_context?.last_state_modified,
  ])

  return app_context?.[ APP_CONTEXT_MODULE_ID ]

  async function async_action_initialize({
    data_indexes_module,
    callback_update_module_state,
    async_callback_load_indexes_from_disk,
    data_indexes_action,
  }) {
    const error_msg_prefix = 'cannot initialize data indexes module'

    if (!data_indexes_module) throw new Error( `${ error_msg_prefix } -- module not found` )
    if (!callback_update_module_state) throw new Error( `${ error_msg_prefix } -- module state update function not found` )
    if (!async_callback_load_indexes_from_disk) throw new Error( `${ error_msg_prefix } -- load from disk function not found` )

    if (data_indexes_action) throw new Error( 'cannot initialize data indexes hook -- another action is currently running' )

    callback_update_module_state({ action: ACTIONS.INITIALIZE })

    const log_entry = [
      'action=initialize-hook',
      'hook=use-data-indexes',
    ]

    let seeded = false
    let source

    // load indexes from disk, if any
    try {
      await async_callback_load_indexes_from_disk()

      seeded = true
      source = 'disk'
    }

    catch (seed_from_disk_error) {
      const disk_seed_error_msg = seed_from_disk_error?.stack
        ?? seed_from_disk_error?.message
        ?? 'unspecified error'

      console.log( `action=seed-data-indexes location=disk success=false error="${ disk_seed_error_msg }"` )
    }

    log_entry.push(
      'success=true',
      `seeded=${ seeded }`,
      seeded
        ? `source=${ source }`
        : 'seeding_error="failed to load data indexes from disk'
    )

    console.log( log_entry.join(' ') )

    callback_update_module_state({ action: null, initialized: true })
  }

  async function async_load_indexes_from_disk({
    username,
    sync_id,
    indexed_db_client,
    callback_save_indexes_to_memory,
  } = {}) {
    const error_msg_prefix = 'cannot load data indexes from disk'

    if (!indexed_db_client) throw new Error( `${ error_msg_prefix } -- indexed-db client not specified` )
    if (!username) throw new Error( `${ error_msg_prefix } -- username not specified` )
    if (!sync_id) throw new Error( `${ error_msg_prefix } -- sync id not specified` )
    if (!callback_save_indexes_to_memory) throw new Error( `${ error_msg_prefix } -- sync id not specified` )

    const { transaction, transaction_completed_promise } = utils.indexed_db.create_transaction({
      client: indexed_db_client,
      databases: [ DISK_DATABASE_NAME ],
      permissions: utils.indexed_db.create_transaction.TRANSACTION_PERMISSIONS.READ_ONLY,
    })

    const data_indexes_db = transaction.objectStore( DISK_DATABASE_NAME )
    const data_indexes_keys = data_indexes_db.getAllKeys()
    const data_indexes_vals = data_indexes_db.getAll()

    await transaction_completed_promise

    const data_indexes_on_disk = data_indexes_keys.result.reduce(( map, key, index ) => {
      map[ key ] = data_indexes_vals.result[ index ]
      return map
    }, {})

    if (!data_indexes_on_disk) throw new Error( `${ error_msg_prefix } -- no data indexes not found on disk` )
    if (!data_indexes_on_disk[ username ]) throw new Error( `${ error_msg_prefix } -- no data indexes for user "${ username }" found on disk` )

    const {
      username: on_disk_owner,
      sync_id: on_disk_sync_id,
      data_indexes: on_disk_data_indexes,
    } = data_indexes_on_disk[ username ]

    if (on_disk_owner !== username) throw new Error( `${ error_msg_prefix } -- no data indexes for user "${ username }" found on disk` )
    if (on_disk_sync_id !== sync_id) throw new Error( `${ error_msg_prefix } -- data indexes on disk are from a different sync` )

    callback_save_indexes_to_memory({ data_indexes: on_disk_data_indexes, sync_id: on_disk_sync_id })

    return on_disk_data_indexes
  }

  async function async_save_indexes_to_disk({
    indexed_db_client,
    username,
    sync_id,
    data_indexes,
  } = {}) {
    const error_msg_prefix = 'cannot save data indexes to disk'

    if (!indexed_db_client) throw new Error( `${ error_msg_prefix } -- indexed-db client not specified` )
    if (!username) throw new Error( `${ error_msg_prefix } -- username not specified` )
    if (!sync_id) throw new Error( `${ error_msg_prefix } -- sync id not specified` )

    const { transaction, transaction_completed_promise } = utils.indexed_db.create_transaction({
      client: indexed_db_client,
      databases: [ DISK_DATABASE_NAME ],
      permissions: utils.indexed_db.create_transaction.TRANSACTION_PERMISSIONS.READWRITE,
    })

    const data_indexes_db = transaction.objectStore( DISK_DATABASE_NAME )
    const data_indexes_disk_formatted = {
      sync_id,
      username,
      data_indexes,
    }

    data_indexes_db.clear()
    data_indexes_db.add( data_indexes_disk_formatted, username )

    await transaction_completed_promise

    console.log( `action=save-data-indexes location=disk success=true username=${ username } sync=${ sync_id }` )
  }

  function save_data_indexes_to_memory({
    data_indexes,
    username,
    sync_id,
    callback_update_module_state,
  } = {}) {
    const error_msg_prefix = 'cannot save data indexes to memory'

    if (!username) throw new Error( `${ error_msg_prefix } -- username not found` )
    if (!sync_id) throw new Error( `${ error_msg_prefix } -- sync id not found` )
    if (!callback_update_module_state) throw new Error( `${ error_msg_prefix } -- module state update function not found` )

    callback_update_module_state({
      sync_id,
      username,
      data_indexes,
    })
  }

  function delete_indexes_from_memory({
    callback_update_module_state,
  }) {
    if (!callback_update_module_state) throw new Error( 'cannot delete data indexes from memory -- module state update function not found' )

    callback_update_module_state({
      data_indexes: null,
      username: null,
      sync_id: null,
    })

    console.log( 'action=clear-data-indexes location=memory success=true' )
  }

  async function async_action_create_indexes({
    data_indexes_sync_id,
    content_data_sync_id,
    content_data,
    data_indexes_action,
    callback_update_module_state,
    async_callback_save_indexes_to_disk,
    callback_save_indexes_to_memory,
  }) {
    const error_msg_prefix = 'cannot create content indexes'

    if (!content_data_sync_id) throw new Error( `${ error_msg_prefix } -- content data's sync id not specified` )
    if (data_indexes_sync_id === content_data_sync_id) throw new Error( `${ error_msg_prefix } -- indexes for current content data on device already exists` )

    if (!async_callback_save_indexes_to_disk) throw new Error( `${ error_msg_prefix } -- function to save indexes to disk not specified` )
    if (!callback_save_indexes_to_memory) throw new Error( `${ error_msg_prefix } -- function to save indexes to memory not specified` )
    if (!callback_update_module_state) throw new Error( `${ error_msg_prefix } -- module state update function not specified` )

    if (data_indexes_action) throw new Error( `${ error_msg_prefix } -- another action is currently running` )

    callback_update_module_state({ action: ACTIONS.CREATE_DATA_INDEXES })

    const content_indexes = {}

    content_indexes.authors = Object.keys( content_data?.authors ?? {} )
    content_indexes.content = Object.keys( content_data?.content ?? {} )
    content_indexes.domains = Object.keys( content_data?.domains ?? {} )
    content_indexes.images = Object.keys( content_data?.images ?? {} )
    content_indexes.videos = Object.keys( content_data?.videos ?? {} )

    content_indexes.content_archived = content_indexes.content
      .filter( content_id => content_data.user_content_states?.[ content_id ]?.status === '1' )

    content_indexes.content_favorited = content_indexes.content
      .filter( content_id => content_data.user_content_states?.[ content_id ]?.favorite === '1' )

    content_indexes.content_tagged = content_indexes.content
      .filter( content_id => content_data.content_to_user_tags?.[ content_id ]?.length > 0 )

    await async_callback_save_indexes_to_disk({ data_indexes: content_indexes, sync_id: content_data_sync_id })

    callback_save_indexes_to_memory({ data_indexes: content_indexes, sync_id: content_data_sync_id })

    callback_update_module_state({ action: null })
  }
}
