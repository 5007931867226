import './style.css'

const ExpandedViewLabel = ({ className, title }) => {
  return <>
    <div
      className={ `ExpandedViewLabel${ className ? ` ${ className }` : '' }` }
    >
      {
        title && (
          <div className="Title">
            <span>{ title }</span>
          </div>
        )
      }
    </div>
  </>
}

export default ExpandedViewLabel
