export default create_module

function create_module({
  module_id,
  app_context,
  set_app_context,
}) {
  if (!module_id) throw new Error( 'cannot create a new app context module -- module id not specified' )

  const error_msg_prefix = `cannot create app context module "${ module_id }"`

  if (!set_app_context) throw new Error( `${ error_msg_prefix } -- app context update function not specified` )
  if (app_context?.[ module_id ]) throw new Error( `${ error_msg_prefix } -- module already exists` )

  set_app_context( latest_context => {
    return {
      ...latest_context,
      [ module_id ]: {
        state: {
          initialized: false,
          action: null,
        }
      },
      last_state_modified: (new Date()).toISOString(),
    }
  })
}
