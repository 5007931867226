import './style.css'

const ExpandedViewItem = ({ className, icon, label, onClick }) => {
  return (
    <div
      className={ `ExpandedViewItem${ className ? ` ${ className }` : '' }${ typeof onClick === 'function' ? ' clickable' : '' }` }
      onClick={ () => onClick?.() }
    >
      <span className="Icon">{ icon }</span>
      { ' ' }
      <span className="Label">{ label }</span>
    </div>
  )
}

export default ExpandedViewItem
