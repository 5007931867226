import { useState } from 'react'
import './style.css'

const CollapsibleContainer = ({ toggle, children, initialize_collapsed }) => {
  const [ is_collapsed, set_is_collapsed ] = useState( initialize_collapsed )

  return <>
    <div
      className={ `CollapsibleContainer${ is_collapsed ? ' collapsed' : '' }`}
    >
      <div
        className={ `CollapsibleContainerToggleWrapper` }
        onClick={ () => { set_is_collapsed( !is_collapsed ) }}
      >
        { toggle }
      </div>

      { !is_collapsed && children }
    </div>
  </>
}

export default CollapsibleContainer
