export default set_module_actions

function set_module_actions({
  set_app_context,
  app_context,
  module_id,
  actions,
}) {
  if (!module_id) throw new Error( 'cannot set module actions -- module id not specified' )

  const error_msg_prefix = `cannot set actions for module "${ module_id }"`

  if (!set_app_context) throw new Error( `${ error_msg_prefix } -- app context update function not specified` )
  if (!app_context?.[ module_id ]) throw new Error( `${ error_msg_prefix } -- module not found` )

  set_app_context( latest_context => {
    const { [ module_id ]: target_module, ...latest_context_minus_target_module } = latest_context
    const { actions: target_module_actions, ...target_module_minus_actions } = target_module ?? {}

    return {
      ...latest_context_minus_target_module,
      [ module_id ]: {
        ...target_module_minus_actions,
        actions,
      },
    }
  })
}
