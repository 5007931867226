import { useCallback, useEffect, useState } from 'react'
import useIndexedDB from '../useIndexedDB'

import appContext from '../../context'
import utils from '../../utils'

const { useAppContext } = appContext

const SEC_IN_MS = 1000
const UPDATE_CONTENT_DATA_DEBOUNCE_DURATIONS_SECS = [
  0.64,
  3,
  10,
  30,
  60,
  180,
]

const API_URL = process.env.REACT_APP_SPARKPOCKETJOY_API_URL
if (!API_URL) throw new Error( 'api url not found' )

export const ACTIONS = {
  INITIALIZE: 'ACTION_INITIALIZE',
  UPDATE_CONTENT_DATA: 'ACTION_UPDATE_CONTENT_DATA',
}

const APP_CONTEXT_MODULE_ID = 'content_data'

export default useContentData

function useContentData() {
  useIndexedDB()

  const [ app_context, set_app_context ] = useAppContext()
  const {
    indexed_db_client,

    username,
    access_token,

    current_sync_status,
    sync_id,

    content_data,
    content_data_module,
    content_data_initialized,
    content_data_module_state,
    content_data_action,
    content_data_sync_id,
  } =
    utils.app_context.pluck({
      app_context,
      keys_to_pluck: [
        'indexed_db_client',

        'username',
        'access_token',

        'current_sync_status',
        'sync_id',

        'content_data',
        'content_data_module',
        'content_data_initialized',
        'content_data_module_state',
        'content_data_action',
        'content_data_sync_id',
      ]
    })

  // bind functions to state with useCallback
  // ===

  const callback_create_content_data_module = useCallback(() => {
    return utils.app_context.create_module({
      module_id: APP_CONTEXT_MODULE_ID,
      set_app_context,
      app_context,
    })
  }, [
    set_app_context,
    app_context,
  ])

  const callback_update_module_state = useCallback( state_updates => {
    return utils.app_context.update_module_state({
      module_id: APP_CONTEXT_MODULE_ID,
      set_app_context,
      app_context,
      state_updates,
    })
  }, [
    set_app_context,
    app_context,
  ])

  const async_callback_action_initialize = useCallback( async () => {
    return await async_action_initialize({
      indexed_db_client,
      username,
      access_token,
      sync_id,
      content_data_module,
      content_data_action,
      content_data_initialized,
      update_module_state: callback_update_module_state,
    })
  }, [
    indexed_db_client,
    username,
    access_token,
    sync_id,
    content_data_module,
    content_data_action,
    content_data_initialized,
    callback_update_module_state,
  ])

  const async_callback_action_update_content_data = useCallback( async () => {
    return await async_action_update_content_data({
      indexed_db_client,
      username,
      access_token,
      sync_id,
      content_data_initialized,
      content_data_sync_id,
      content_data,
      content_data_action,
      current_sync_status,
      update_module_state: callback_update_module_state,
    })
  }, [
    indexed_db_client,
    username,
    access_token,
    sync_id,
    content_data_initialized,
    content_data_sync_id,
    content_data,
    content_data_action,
    current_sync_status,
    callback_update_module_state,
  ])

  // setup hook effects
  // ===

  // 1. create module if it doesn't exist
  useEffect(() => {
    if (content_data_module) return

    callback_create_content_data_module()
  }, [
    content_data_module,
    callback_create_content_data_module,
  ])

  // 2. initialize module if it exists but hasn't been initialized
  useEffect(() => {
    if (!indexed_db_client || !username || !access_token || !sync_id || !content_data_module) return
    if (content_data_initialized || content_data_action) return

    async_callback_action_initialize()
  }, [
    indexed_db_client,
    username,
    access_token,
    sync_id,
    content_data_module,
    content_data_action,
    content_data_initialized,
    async_callback_action_initialize,
  ])

  // 3. drop in-memory content if logged-in user changes or there is no current sync
  const { username: content_data_owner } = content_data_module_state ?? {}
  useEffect(() => {
      if (!content_data_owner || !content_data_sync_id) return

      if (
        (content_data_owner === username)
        && sync_id
      ) return

      callback_update_module_state({
        sync_id: null,
        username: null,
        content_data: null,
      })
  }, [
    content_data_owner,
    content_data_sync_id,
    username,
    sync_id,
    callback_update_module_state,
  ])

  // 4. periodically attempt to update content data if data's sync id does not match current sync id
  const [ update_debounce_duration_index, set_update_debounce_duration_index ] = useState( 0 )
  useEffect(() => {
    if (!indexed_db_client || !username || !access_token || !sync_id) return
    if (!content_data_initialized) return
    if (sync_id === content_data_sync_id) return
    if (content_data_action) return

    const debounce_secs = UPDATE_CONTENT_DATA_DEBOUNCE_DURATIONS_SECS[ update_debounce_duration_index ]
    const debouncer = setTimeout( async () => {
      try {
        await async_callback_action_update_content_data()

        // reset debounce duration index, just in case it was incremented
        set_update_debounce_duration_index( 0 )
      }

      catch (update_error) {

        // increment debounce duration index, if necessary
        if ( update_debounce_duration_index < ( UPDATE_CONTENT_DATA_DEBOUNCE_DURATIONS_SECS.length - 1 )) {
          set_update_debounce_duration_index( update_debounce_duration_index + 1 )
        }
      }
    }, debounce_secs * SEC_IN_MS )

    return () => {
      clearTimeout( debouncer )
    }
  }, [
    indexed_db_client,
    username,
    access_token,
    sync_id,
    content_data_sync_id,
    content_data_action,
    content_data_initialized,
    update_debounce_duration_index,
    async_callback_action_update_content_data,
    app_context?.last_state_modified,
  ])

  return app_context?.[ APP_CONTEXT_MODULE_ID ]
}

async function async_action_initialize({
  indexed_db_client,
  username,
  access_token,
  sync_id,
  content_data_module,
  update_module_state,
  content_data_action,
  content_data_initialized,
}) {
  const error_msg_prefix = 'cannot initialize content data module'

  if (!indexed_db_client) throw new Error( `${ error_msg_prefix } -- indexed db client not specified` )
  if (!username || !access_token) throw new Error( `${ error_msg_prefix } -- pocket user credentials not specified` )
  if (!sync_id) throw new Error( `${ error_msg_prefix } -- sync id not specified` )
  if (!content_data_module) throw new Error( `${ error_msg_prefix } -- content data module has not yet been created` )
  if (!update_module_state) throw new Error( `${ error_msg_prefix } -- content data module state updater function not specified` )

  if (content_data_initialized) throw new Error( `${ error_msg_prefix } -- content data module has already been initialized` )
  if (content_data_action) throw new Error( `${ error_msg_prefix } -- another action is currently running` )

  update_module_state({ action: ACTIONS.INITIALIZE })

  // attempt to seed memory from disk
  try {
    const content_data_on_disk = await async_get_content_from_disk({
      indexed_db_client,
      username,
    })

    if (!content_data_on_disk.hasOwnProperty( 'content_data' )) throw new Error( `${ error_msg_prefix } -- unexpected data format on disk` )
    if (!content_data_on_disk.hasOwnProperty( 'sync_id' )) throw new Error( `${ error_msg_prefix } -- unexpected data format on disk` )

    update_module_state({
      action: null,
      initialized: true,
      username,
      sync_id: content_data_on_disk.sync_id,
      content_data: content_data_on_disk.content_data,
    })

    console.log( `action=initialize-use-content-data success=true seeded=true source=disk sync=${ content_data_on_disk.sync_id }` )
  }

  catch (disk_seed_error) {
    console.log( `action=get-content-data-initialization-seed source=disk success=false error="${ disk_seed_error?.stack ?? disk_seed_error?.message ?? 'unspecified error' }"` )

    update_module_state({ action: null, initialized: true })
    console.log( 'action=initialize-use-content-data success=true seeded=false seeding_error="unable to seed memory with stored content data"' )
  }
}

async function async_action_update_content_data({
  indexed_db_client,
  username,
  access_token,
  sync_id,
  content_data_initialized,
  content_data_sync_id,
  content_data_action,
  update_module_state,
  current_sync_status,
  content_data,
}) {
  const error_msg_prefix = 'cannot update content data'

  if (!indexed_db_client) throw new Error( `${ error_msg_prefix } -- indexed db client not specified` )
  if (!username || !access_token) throw new Error( `${ error_msg_prefix } -- pocket user credentials not specified` )
  if (!sync_id) throw new Error( `${ error_msg_prefix } -- sync id not specified` )
  if (!content_data_initialized) throw new Error( `${ error_msg_prefix } -- module has not yet been initialized` )
  if (sync_id === content_data_sync_id) throw new Error( `${ error_msg_prefix } -- content data appears to be up-to-date` )

  if (content_data_action) throw new Error( `${ error_msg_prefix } -- another action is currently running` )

  update_module_state({ action: ACTIONS.UPDATE_CONTENT_DATA })

  try {
    switch (current_sync_status?.type) {

      case 'full-sync':
        {
          const full_content_data = await async_get_content_from_api({
            sync_id,
            username,
            access_token,
            indexed_db_client,
          })

          await async_set_content_to_disk({
            indexed_db_client,
            username,
            sync_id,
            content_data: full_content_data,
          })

          update_module_state({
            sync_id,
            username,
            content_data: full_content_data,
          })

          console.log( `action=update-content-data success=true type=full-sync username=${ username } sync=${ sync_id }` )

          update_module_state({ action: null })
        }
        break

      case 'partial-sync':
        {
          if (!content_data_sync_id) throw new Error( `${ error_msg_prefix } -- partial syncs cannot be initiated if there is no content data already synced to device` )
          if (content_data_sync_id !== current_sync_status?.source_sync_id) throw new Error( `${ error_msg_prefix } -- partial syncs cannot be initiated if content data's sync is not the source for the latest sync` )

          const content_patch = await async_get_content_from_api({
            sync_id,
            username,
            access_token,
            indexed_db_client,
          })

          const {
            deleted_saves: deleted_content_ids,
            updates,
          } = content_patch ?? {}

          const update_content_ids = [
            ...deleted_content_ids,
            ...(
              updates?.entities?.content
                ? Object.keys( updates.entities.content )
                : []
            ),
          ]

          // remove all saves in the partial update from in-memory content
          update_content_ids?.forEach( content_id => {
            const content = content_data?.content?.[ content_id ]
            if (!content) return

            const user_content_state = content_data?.user_content_states?.[ content_id ]

            const has_domain = content.hasOwnProperty( 'domain_id' )
            const has_tags = user_content_state?.has_tag === '1'
            const has_authors = content.authors_attached === '1'
            const has_images = content.images_attached === '1'
            const has_videos = content.videos_attached === '1'

            if (has_images) {
              const content_image_ids = content_data?.content_to_images?.[ content_id ]

              // delete entities: images
              content_image_ids?.forEach( image_id => {
                delete content_data?.images?.[ image_id ]
              })

              // delete relationship: content_to_images
              delete content_data?.content_to_images?.[ content_id ]
            }

            if (has_videos) {
              const content_video_ids = content_data?.content_to_videos?.[ content_id ]

              // delete entities: videos
              content_video_ids?.forEach( video_id => {
                delete content_data?.videos?.[ video_id ]
              })

              // delete relationships: content_to_videos
              delete content_data?.content_to_videos?.[ content_id ]
            }

            if (has_domain) {
              const domain_id = content.domain_id
              const domain_to_content_relationship = content_data?.domain_to_content?.[ domain_id ]

              if (domain_to_content_relationship) {
                const content_index = domain_to_content_relationship?.indexOf( content_id )

                if (Number.isInteger( content_index ) && (content_index > -1)) {
                  if (domain_to_content_relationship.length > 1) {
                    // update relationship: remove content_id
                    content_data.domain_to_content[ domain_id ].splice( content_index, 1 )
                  }

                  else {
                    // delete domain's entity and relationships
                    delete content_data.domains[ domain_id ]
                    delete content_data.domain_to_content[ domain_id ]
                  }
                }
              }
            }

            if (has_authors) {
              const content_author_ids = content_data?.content_to_authors?.[ content_id ]

              content_author_ids?.forEach( author_id => {
                const author_to_content_relationship = content_data?.author_to_content?.[ author_id ]

                if (author_to_content_relationship) {
                  const content_index = author_to_content_relationship?.indexOf( content_id )

                  if (Number.isInteger( content_index ) && ( content_index > -1 )) {
                    if (author_to_content_relationship.length > 1) {
                      // update relationship: remove content_id
                      content_data.author_to_content[ author_id ].splice( content_index, 1 )
                    }

                    else {
                      // delete author's entity and relationships
                      delete content_data.authors[ author_id ]
                      delete content_data.author_to_content[ author_id ]
                    }
                  }
                }
              })

              // delete relationship: content_to_authors
              delete content_data?.content_to_authors?.[ content_id ]
            }

            if (has_tags) {
              const content_tag_ids = content_data?.content_to_user_tags?.[ content_id ]

              content_tag_ids?.forEach( user_tag => {
                const user_tag_to_content_relationship = content_data?.user_tag_to_content?.[ user_tag ]

                if (user_tag_to_content_relationship) {
                  const content_index = user_tag_to_content_relationship?.indexOf( content_id )

                  if (Number.isInteger( content_index ) && ( content_index > -1 )) {
                    if (user_tag_to_content_relationship.length > 1) {
                      // update relationship: remove content id
                      content_data.user_tag_to_content[ user_tag ].splice( content_index, 1 )
                    }

                    else {
                      // delete user tag's entity and relationships
                      delete content_data.user_tags[ user_tag ]
                      delete content_data.user_tag_to_content[ user_tag ]
                    }
                  }
                }
              })

              // delete relationship: content_to_user_tags
              delete content_data?.content_to_user_tags?.[ content_id ]
            }

            delete content_data?.user_content_states?.[ content_id ]
            delete content_data?.content?.[ content_id ]
          })

          // add new / updated saves to in-memory content
          const update_entity_types = Object.keys( updates?.entities ?? {} )

          update_entity_types.forEach( entity_type => {
            if (entity_type === 'user_tags') {
              if (!content_data[ entity_type ]) content_data[ entity_type ] = []

              updates.entities[ entity_type ].forEach( user_tag_to_add => {
                const current_user_tag_index = content_data[ entity_type ].indexOf( user_tag_to_add )

                if (current_user_tag_index === -1) content_data[ entity_type ].push( user_tag_to_add )
              })
            }

            else {
              if (!content_data[ entity_type ]) content_data[ entity_type ] = {}

              const entity_type_ids = Object.keys( updates.entities[ entity_type ] )

              entity_type_ids.forEach( entity_id => {
                content_data[ entity_type ][ entity_id ] = updates.entities[ entity_type ][ entity_id ]
              })
            }
          })

          const update_relationship_types = Object.keys( updates?.relationships ?? {} )

          update_relationship_types.forEach( relationship_type => {
            if (!content_data[ relationship_type ]) content_data[ relationship_type ] = {}

            const relationship_primary_ids = Object.keys( updates.relationships[ relationship_type ] )

            relationship_primary_ids.forEach( relationship_primary_id => {
              if (!content_data[ relationship_type ][ relationship_primary_id ]) content_data[ relationship_type ][ relationship_primary_id ] = []

              content_data[ relationship_type ][ relationship_primary_id ].push(
                ...updates.relationships[ relationship_type ][ relationship_primary_id ],
              )
            })
          })

          // delete empty user tags
          content_data.user_tags.forEach( user_tag => {
            const tag_has_content = content_data?.user_tag_to_content?.[ user_tag ]
              ? true
              : false

            if (tag_has_content) return;

            const user_tag_index = content_data?.user_tags?.indexOf( user_tag )
            if (user_tag_index === -1) return;

            content_data.user_tags.splice( user_tag_index, 1 )
          })

          await async_set_content_to_disk({
            indexed_db_client,
            username,
            sync_id,
            content_data,
          })

          update_module_state({
            sync_id,
            username,
            content_data,
          })

          console.log( `action=update-content-data success=true type=partial-sync username=${ username } sync=${ sync_id }` )

          update_module_state({ action: null })
        }
        break

      default:
        {
          console.log( 'TODO: implement recovery strategy for unexpected sync status type' )
          console.log( 'HACK: intentionally not throwing an error or ending current action, so content data will not continue to pointlessly retry to update content data' )
        }
    }
  }

  catch (update_error) {
    console.log( `action=update-content-data success=false error="${ update_error?.stack ?? update_error?.message ?? 'unspecified error' }"` )

    console.log( 'TODO: implement hander for specific error -- content data no longer exists on our servers' )

    update_module_state({ action: null })

    throw update_error
  }
}

async function async_get_content_from_api({
  sync_id,
  username,
  access_token,
  indexed_db_client,
}){
  if (!indexed_db_client) throw new Error( 'indexed db client not specified' )
  if (!username || !access_token) throw new Error( 'pocket api credentials not specified' )
  if (!sync_id) throw new Error( 'sync id not specified' )

  const get_dataset_request = await fetch(
    API_URL + '/get-dataset',
    {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        access_token,
        username,
        sync_id,
      })
    }
  )

  const get_dataset_response = await get_dataset_request.json()
  const { success, dataset: dataset_stringified } = get_dataset_response

  if (!success) throw new Error( get_dataset_response.error ?? 'unspecified error' )

  console.log( `action=get-content-data-from-api success=true username=${ username } sync=${ sync_id }` )

  const content_data_from_api = JSON.parse( dataset_stringified )
  return content_data_from_api
}

async function async_get_content_from_disk({
  indexed_db_client,
  username,
}) {
  if (!indexed_db_client) throw new Error( 'indexed db client not specified' )
  if (!username) throw new Error( 'username not specified' )

  const { transaction, transaction_completed_promise } = utils.indexed_db.create_transaction({
    client: indexed_db_client,
    databases: [ 'content-data' ],
    permissions: utils.indexed_db.create_transaction.TRANSACTION_PERMISSIONS.READ_ONLY,
  })

  const content_data_db = transaction.objectStore( 'content-data' )
  const content_data_keys = content_data_db.getAllKeys()
  const content_data_vals = content_data_db.getAll()

  await transaction_completed_promise

  const content_data_disk_wrapper = content_data_keys.result.reduce(( map, key, index ) => {
    map[ key ] = content_data_vals.result[ index ]
    return map
  }, {})

  if (!content_data_disk_wrapper) throw new Error( 'content data not found on disk' )
  if (!content_data_disk_wrapper[ username ]) throw new Error( 'content data for logged-in user not found on disk' )

  const {
    username: on_disk_owner,
    sync_id: on_disk_sync_id,
    content_data: content_data_on_disk,
  } = content_data_disk_wrapper[ username ]

  if (on_disk_owner !== username) throw new Error( 'content data on disk does not belong to logged-in user' )

  return {
    content_data: content_data_on_disk,
    sync_id: on_disk_sync_id,
  }
}

async function async_set_content_to_disk({
  indexed_db_client,
  username,
  sync_id,
  content_data: content_data_to_save,
}) {
  if (!indexed_db_client) throw new Error( 'indexed db client not specified' )
  if (!username) throw new Error( 'username not specified' )
  if (!sync_id) throw new Error( 'sync id not specified' )

  const { transaction, transaction_completed_promise } = utils.indexed_db.create_transaction({
    client: indexed_db_client,
    databases: [ 'content-data' ],
    permissions: utils.indexed_db.create_transaction.TRANSACTION_PERMISSIONS.READWRITE,
  })

  const content_data_db = transaction.objectStore( 'content-data' )
  const content_data_key = username
  const content_data_disk_formatted = {
    sync_id,
    username,
    content_data: content_data_to_save,
  }

  content_data_db.clear()
  content_data_db.add( content_data_disk_formatted, content_data_key )

  await transaction_completed_promise

  console.log( `action=set-content-data-to-disk success=true username=${ username } sync=${ sync_id }` )

  return content_data_disk_formatted
}
