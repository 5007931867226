export default async_open_database

async function async_open_database({ name, version = 1, create_database = create_default_database }) {
  if (!name) throw new Error( 'database name not specified' )

  return new Promise(( resolve, reject ) => {
    const open_database_request = indexedDB.open( name, version )

    open_database_request.onsuccess = () => {
      console.log( `action=open-indexed-db success=true name=${ name } version=${ version }` )
      resolve( open_database_request.result )
    }

    open_database_request.onerror = (event) => {
      console.log( `action=open-indexed-db success=false event="${ event }"` )
      reject( new Error( `failed to open database "${ name }"` ) )
    }

    open_database_request.onupgradeneeded = (event) => {
      const indexed_db_connection = event.target.result

      try {
        create_database({ indexed_db: indexed_db_connection, name })
      }

      catch (create_database_error) {
        reject( create_database_error )
      }
    }
  })
}

function create_default_database({ indexed_db: indexed_db_connection, name: db_name }) {
  indexed_db_connection.onerror = db_creation_error => {
    console.log( `action=create-indexed-db success=false error="${ db_creation_error.stack ?? db_creation_error.message ?? 'unspecified error' }"` )
    throw db_creation_error
  }

  indexed_db_connection.createObjectStore( db_name )
  console.log( `action=create-indexed-db success=true name=${ db_name }` )
}