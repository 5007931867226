import { useEffect } from 'react'

import appContext from '../../context'
import utils from '../../utils'
import indexed_db_schemas from '../../database'

const APP_CONTEXT_MODULE_ID = 'indexed_db'

const { useAppContext } = appContext
const indexed_db_schema_ids = Object
  .keys( indexed_db_schemas )
  .map( schema_id => parseInt( schema_id ) )

export const ACTIONS = {
  INITIALIZE: 'ACTION_INITIALIZE',
}

export default useIndexedDB

function useIndexedDB() {
  const [ app_context, set_app_context ] = useAppContext()

  const plucked_app_context_map = utils.app_context.pluck({
    app_context,
    keys_to_pluck: [
      'indexed_db_module',
      'indexed_db_action',
      'indexed_db_initialized',
    ]
  })

  const {
    indexed_db_module,
    indexed_db_action,
    indexed_db_initialized,
  } = plucked_app_context_map

  // create module
  useEffect(() => {
    if (indexed_db_module) return;

    utils.app_context.create_module({
      module_id: APP_CONTEXT_MODULE_ID,
      set_app_context,
      app_context,
    })
  }, [
    indexed_db_module,
    set_app_context,
    app_context,
  ])

  // initialize module
  useEffect(() => {
    if (!indexed_db_module) return
    if (indexed_db_initialized) return
    if (indexed_db_action) return

    async_initialize({
      indexed_db_module,
      indexed_db_initialized,
      indexed_db_action,
      app_context,
      set_app_context,
    })
  }, [
    indexed_db_module,
    indexed_db_initialized,
    indexed_db_action,
    app_context,
    set_app_context,
  ])

  return app_context?.[ APP_CONTEXT_MODULE_ID ]

  async function async_initialize({
    indexed_db_module,
    indexed_db_initialized,
    indexed_db_action,
    app_context,
    set_app_context,
  }) {
    const error_msg_prefix = `cannot initialize module "${ APP_CONTEXT_MODULE_ID }"`

    if (!indexed_db_module) throw new Error ( `${ error_msg_prefix } -- module does not exist`)
    if (indexed_db_initialized) throw new Error ( `${ error_msg_prefix } -- module is already initialized`)
    if (indexed_db_action) throw new Error ( `${ error_msg_prefix } -- another action is running`)

    utils.app_context.update_module_state({
      module_id: APP_CONTEXT_MODULE_ID,
      set_app_context,
      app_context,
      state_updates: {
        action: ACTIONS.INITIALIZE,
      }
    })

    let initialization_error
    let device_support
    let connection
    let schema_db
    let schema_version

    try {
      // check if device supports indexed db
      device_support = indexedDB && indexedDB.open
        ? true
        : false

      utils.app_context.update_module_state({
        module_id: APP_CONTEXT_MODULE_ID,
        set_app_context,
        app_context,
        state_updates: {
          device_support,
        }
      })

      if (device_support) {

        // connect and setup database schema
        const invalid_indexed_db_schema_ids = indexed_db_schema_ids.filter( schema_id => !Number.isInteger( schema_id ) || schema_id < 1 )
        if (invalid_indexed_db_schema_ids.length > 0) throw new Error( 'invalid schema ids found -- cannot guarantee selection of the most up-to-date schema because invalid schema ids exist' )

        const sorted_valid_schema_ids = [ ...indexed_db_schema_ids ].sort()
        const newest_schema_id = sorted_valid_schema_ids[ sorted_valid_schema_ids.length - 1 ]
        const newest_schema = indexed_db_schemas[ newest_schema_id ]
        const { name: db_name, create_database } = newest_schema

        if (!db_name || typeof db_name !== 'string') throw new Error( 'indexed-db schema must have a database name, and it must be a string' )
        if (!create_database || typeof create_database !== 'function') throw new Error( 'indexed-db schema must have a create function' )

        connection = await utils.indexed_db.async_open({
          name: db_name,
          create_database,
          version: newest_schema_id,
        })

        schema_db = db_name
        schema_version = newest_schema_id
      }
    }

    catch (error) {
      initialization_error = error
    }

    const state_updates = {
      action: null,
      initialized: initialization_error ? false : true,
    }

    const log_entry = [
      'action=initialize-module',
      'module=indexed-db',
      `success=${ state_updates.initialized }`,
    ]

    if (initialization_error) {
      log_entry.push( `error="${ initialization_error?.stack ?? initialization_error?.message ?? 'unspecified error' }"` )
    }

    else {
      state_updates.device_support = device_support

      log_entry.push(
        `device_support=${ device_support }`,
      )

      if (device_support) {
        state_updates.connection = connection

        log_entry.push( `connected=${ connection ? true : false }` )

        if (connection) {
          log_entry.push( `database=${ schema_db } schema=${ schema_version }` )
        }
      }
    }

    utils.app_context.update_module_state({
      module_id: APP_CONTEXT_MODULE_ID,
      set_app_context,
      app_context,
      state_updates,
    })

    console.log( log_entry.join(' ') )
  }
}
