import { createContext, useContext, useState } from 'react'

const AppContext = createContext()

const AppContextProvider = props => {
  const [state, setState] = useState({})
  return <AppContext.Provider value={[ state, setState ]} {...props} />
}

const useAppContext = () => {
  const context = useContext(AppContext)
  if (!context) throw new Error('useAppContext must be used within an AppContextProvider')

  return context
}

const api = {
  AppContextProvider,
  useAppContext,
}

export default api
