export default update_module_state

function update_module_state({
  set_app_context,
  app_context,
  module_id,
  state_updates,
}) {
  if (!module_id) throw new Error( 'cannot update module state -- module id not specified' )

  const error_msg_prefix = `cannot update state for module "${ module_id }"`

  if (!set_app_context) throw new Error( `${ error_msg_prefix } -- app context update function not specified` )
  if (!app_context?.[ module_id ]) throw new Error( `${ error_msg_prefix } -- module not found` )

  set_app_context( latest_context => {
    const { [ module_id ]: target_module, ...latest_context_minus_target_module } = latest_context
    const { state: target_module_state, ...target_module_minus_state } = target_module ?? {}

    return {
      ...latest_context_minus_target_module,
      [ module_id ]: {
        ...target_module_minus_state,
        state: {
          ...target_module_state,
          ...state_updates,
        }
      },
      last_state_modified: (new Date()).toISOString(),
    }
  })
}
