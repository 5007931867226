import appContext from './context'
import Main from './views/Main'

const { AppContextProvider } = appContext

function App() {
  return (
    <AppContextProvider>
      <Main />
    </AppContextProvider>
  )
}

export default App
