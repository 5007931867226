import SparkpocketjoyLogo from '../../components/SparkpocketjoyLogo'

const MainHeader = () => {
  return (
    <div
      className='MainHeader'
      style={{
        textAlign: 'center',
        padding: '1em 0',
      }}
    >
      <SparkpocketjoyLogo />
    </div>
  )
}

export default MainHeader
