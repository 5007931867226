const schema = {
  name: 'sparkpocketjoy',
  version: 2,
  create_database: ({ indexed_db }) => {
    indexed_db.createObjectStore( 'pocket-auth' )
    indexed_db.createObjectStore( 'display-history' )
    indexed_db.createObjectStore( 'sync-status' )
    indexed_db.createObjectStore( 'data-indexed' )
    indexed_db.createObjectStore( 'content-data' )
  },
}

export default schema
