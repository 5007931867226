import createMiddleware from 'cjs-indexing-hash'
import { useEffect, useState } from 'react'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'

import appContext from '../../context'
import usePocketAuth from '../../hooks/usePocketAuth'

import ContentStats from '../ContentStats'
import ContentList from '../ContentList'
import PocketSyncStatus from '../PocketSyncStatus'
import PocketUser from '../PocketUser'
import MainHeader from '../MainHeader'
import MainLanding from '../MainLanding'
import './style.css'

const { useAppContext } = appContext

const VIEWS = {
  ANONYMOUS_USER:
    <>
      <MainLanding />
    </>,

  POCKET_CONNECTED_USER:
    <>
      <MainHeader />
      <PocketUser />
      <PocketSyncStatus />
      <ContentStats />
      <ContentList />
    </>,
}

const Main = () => {
  usePocketAuth()

  const [ appContextState, updateAppContextState ] = useAppContext()

  const [ viewToLoad, setViewToLoad ] = useState()
  const [ currentViewName, setCurrentViewName ] = useState()

  // configure time ago plugin
  useEffect(() => {
    if (appContextState?.time_ago?.initialized) return

    TimeAgo.addDefaultLocale( en )
    updateAppContextState( latest_app_context => {
      return {
        ...latest_app_context,
        time_ago: {
          initialized: true,
          lang: en,
        }
      }
    })
  }, [
    appContextState,
    updateAppContextState,
  ])

  // create middleware stack for context if none exists
  useEffect(() => {
    if (appContextState?.middleware) return

    updateAppContextState( latest_app_context => {
      return {
        ...latest_app_context,
        middleware: createMiddleware(),
      }
    })
  }, [
    appContextState,
    updateAppContextState,
  ])

  // manage current view
  useEffect(() => {
    const {
      indexed_db,
      middleware,
      pocket_auth,
    } = appContextState

    if (!middleware || !indexed_db) return

    const viewToLoadName = pocket_auth?.state?.user?.username
      && pocket_auth?.state?.user?.access_token
        ? 'pocket-connected-user'
        : 'anonymous-user'

    if (currentViewName && (currentViewName === viewToLoadName)) return

    setViewToLoad(
      viewToLoadName === 'pocket-connected-user'
        ? VIEWS.POCKET_CONNECTED_USER
        : VIEWS.ANONYMOUS_USER
    )

    setCurrentViewName( viewToLoadName )
    console.log( `action=log-view view=${ viewToLoadName }` )
  }, [
    currentViewName,
    setViewToLoad,
    setCurrentViewName,
    appContextState,
  ])

  return (
    <div className="ViewWrapper">
      { viewToLoad }
    </div>
  )
}

export default Main
